import { Component, OnInit } from '@angular/core';
import {UtilsService} from '../../../@core/services/util/utils.service';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthService} from '../../../@core/services/base/auth.service';
import {ERROR_PASSWORD_UPDATE} from '../../../@core/utils/TOASTS';

@Component({
  selector: 'ngx-password-reset',
  templateUrl: './password-reset.component.html',
  styleUrls: ['./password-reset.component.scss'],
})
export class PasswordResetComponent implements OnInit {

  private resetToken: string;
  private routeListener: any;
  public password: string;
  public passwordRepeat: string;
  public success: boolean = false;
  constructor(
    private route: ActivatedRoute,
    private authService: AuthService,
    private router: Router,
    private utils: UtilsService
    ) { }

  async ngOnInit(): Promise<void> {
    this.success = false;
    this.routeListener = this.route.params.subscribe(async params => {
      this.resetToken = params['passwordResetToken'];
      if (this.resetToken == undefined) {
        this.utils.showToast(UtilsService.TOAST_STATUS.danger, UtilsService.TOASTS.RESET_TOKEN_NOT_FOUND);
        this.router.navigate(['/login']);
      }
    });
  }

  public validatePassword(): boolean {
    if(this.password != this.passwordRepeat) return false;
    if(this.password.length < 12) return false;
    return true;
  }

  public async resetPassword(): Promise<void> {
    if (!this.validatePassword()) return;
    let success = await this.authService.updatePassword(this.resetToken, this.password);
    if(success){
      this.success = true;
    }else{
      this.utils.showToast(UtilsService.TOAST_STATUS.danger, UtilsService.TOASTS.ERROR_PASSWORD_UPDATE);
    }
  }

  public toLogin(): void {
    this.router.navigate(['/login']);
  }

}
