import {EventEmitter, Injectable} from '@angular/core';
import {BasicService} from '../../utils/basic.service';
import {User} from '../../models/base/User';
import {DataService} from '../util/data.service';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import * as ROUTES from '../../utils/ROUTES';
import {UtilsService} from '../util/utils.service';
import {GenericResponse} from '../../models/util/GenericResponse';

@Injectable({
  providedIn: 'root',
})
export class UserService extends BasicService<User>{

  private self: User = undefined;
  private users: User[] = [];
  private loadUserInterval;
  private loadAllUserInterval;

  public selfLoadedEvent: EventEmitter<any> = new EventEmitter<any>();
  public allLoadedEvent: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    private dataService: DataService,
    private http: HttpClient,
    private utils: UtilsService
  ) {
    super(dataService, http, ROUTES.USER_ROUTE);
    this.loadUserInterval = setInterval(() => {
      this.loadSelf();
    }, 15000);
    this.loadSelf();
    this.loadAllUserInterval = setInterval(async () => {
      if(!this.dataService.loggedInIsCustomer()){
        this.loadUsers();
      }
    }, 60000);
    if(!this.dataService.loggedInIsCustomer()){
      this.loadUsers();
    }
  }

  public async loadUsers(): Promise<void>{
    if (this.dataService.hasValidToken()) {
      let users = await this.getAll();
      if (users != undefined) {
        this.users = users;
        this.allLoadedEvent.next();
      }
    }
  }
  public async loadSelf(): Promise<boolean>{
    if(this.dataService.hasValidToken()) {
      await this.http.get<GenericResponse<User>>(`${ROUTES.PROFILE_ROUTE}/`, this.dataService.getHttpOptions()).toPromise()
        .then((res: GenericResponse<User>) => {
          if (res != undefined && res.status != undefined && res.status == 200 && res.body != undefined) {
            this.self = res.body;
            if(this.self.customerId == undefined || this.self.customerId.length < 1){
              this.dataService.setCustomerStatus(false);
            }else{
              this.dataService.setCustomerStatus(true);
            }
            this.selfLoadedEvent.next();
          }
        });
      return true;
    }
    return false;
  }

  public async inviteUser(id: string): Promise<void> {
    await this.http.post<GenericResponse<undefined>>(`${ROUTES.USER_ROUTE}/${id}/invite`,{}, this.dataService.getHttpOptions()).toPromise()
      .then((res: GenericResponse<undefined>) => {
        if (res != undefined && res.status != undefined && res.status == 200) {
          this.utils.showToast(UtilsService.TOAST_STATUS.success, UtilsService.TOASTS.EMAIL_INVITE_SUCCESS);
        }
      });
  }
  public getSelf(): User{
    return this.utils.deepClone([this.self])[0];
  }

  public getAllUsers(): User[]{
    if(this.users == undefined) return [];
    return this.utils.deepClone(this.users);
  }

  public async create(obj: User): Promise<User> {
    let response: User = undefined;
    await this.http.post<GenericResponse<User>>(`${this.routeBase}`, obj, this.dataService.getHttpOptions()).toPromise()
      .catch((err) => {
        if(typeof err == 'string'){
          if(err.includes('user with email already exists')){
            this.utils.showToast(UtilsService.TOAST_STATUS.danger, UtilsService.TOASTS.USER_WITH_EMAIL_ALREADY_EXISTS);
          }
        }
      })
      .then((res: GenericResponse<User>) => {
        if (res != undefined && res.status != undefined && res.status == 200 && res.body != undefined) {
          response = res.body;
        }
      });
    return response;
  }

}
