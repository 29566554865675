<nb-layout>
  <nb-layout-column class="p-0">
    <div class="bg">
      <div class="container">

        <div class="row">
          <div class="col-12">
            <h3>Willkommen</h3>
          </div>
          <div class="col-12">
            <p>Danke, dass Sie unsere Einladung angenommen haben.<br>Aktivieren Sie Ihren Account indem Sie ein Passwort vergeben.</p>
          </div>
        </div>

        <div class="row inputs">
          <div class="col-12">
            <input autocomplete="off" nbInput placeholder="Passwort" class="password" type="password" [(ngModel)]="password" [ngClass]="{'invalid':passwordNotValid}" (keyup)="onKeypress($event)">
          </div>
          <div class="col-12">
            <input autocomplete="off" nbInput placeholder="Passwort wiederholen" class="password" type="password" [ngClass]="{'invalid':passwordNotValid}" [(ngModel)]="password2" (keyup)="onKeypress($event)">
          </div>
        </div>

        <div class="row btnRow">
          <div class="col-12">
            <button nbButton status="success" (click)="validateAndSend()">Account erstellen</button>
          </div>
        </div>

      </div>
    </div>
  </nb-layout-column>
</nb-layout>
