export const MESSAGE_PLACEHOLDER = '%str_replace%';

export const LOGIN_FAILED = {
  title: 'Login fehlgeschlagen!',
  body: `Es ist ein Fehler aufgetreten, überprüfen Sie Ihre Eingabe und versuchen Sie es erneut!\n ${MESSAGE_PLACEHOLDER}`,
};
export const LOAD_USER_FAILED = {
  title: 'Ladefehler!',
  body: 'Beim Laden Ihrer Daten ist ein Fehler aufgetreten!',
};
export const LOGIN_MISSING = {
  title: 'Fehlerhafte Eingabe!',
  body: 'Bitte füllen Sie alle Eingabefelder aus und versuchen Sie es erneut!',
};
export const TOKEN_EXPIRED = {
  title: 'Aus Sicherheitsgründen wurden Sie abgemeldet!',
  body: 'Bitte melden Sie sich erneut an!',
};
export const USER_NOT_FOUND = {
  title: 'Benutzer nicht gefunden.',
  body: 'Für die angegebene ID wurde kein Benutzer gefunden.',
};
export const SETTING_NOT_FOUND = {
  title: 'Einstellung nicht gefunden.',
  body: 'Für die angegebene ID wurde keine Einstellung gefunden.',
};
export const GENERIC_CREATE_SUCCESS = {
  title: 'Eintrag erstellt.',
  body: 'Neuer Eintrag erfolgreich erstellt.',
};
export const GENERIC_CREATE_FAILED = {
  title: 'Fehler beim erstellen des Eintrags.',
  body: `Bei dem erstellen eines Eintrags ist ein unerwarteter Fehler aufgetreten.\n${MESSAGE_PLACEHOLDER}`,
};
export const GENERIC_UPDATE_SUCCESS = {
  title: 'Eintrag aktualisiert.',
  body: 'Eintrag erfolgreich aktualisiert.',
};
export const GENERIC_CREATE_AMOUNT_SUCCESS = {
  title: 'Einträge erstellt.',
  body: `Es wurden erfolgreich ${MESSAGE_PLACEHOLDER} neue Eiträge erstellt.`,
};
export const CONTENT_NOT_FOUND = {
  title: 'Inhalt nicht gefunden.',
  body: `Der angegebene Inhalt konnte nicht gefunden werden.\n${MESSAGE_PLACEHOLDER}`,
};
export const INTERNAL_SERVER_ERROR = {
  title: 'Fehler beim Verarbeiten einer Anfrage.',
  body: `Bei der Verarbeitung einer Anfrage ist ein unerwarteter Fehler aufgetreten.\n${MESSAGE_PLACEHOLDER}`,
};
export const INSUFFICIENT_PERMISSION = {
  title: 'Fehlende Berechtigung.',
  body: `Sie haben keine Berechtigung für diesen Inhalt.\n${MESSAGE_PLACEHOLDER}`,
};

export const EMAIL_INVITE_SUCCESS = {
  title: 'Einladung versendet.',
  body: `Die Einladung wurde erfolgreich versendet.`,
};

export const TOKEN_NOT_FOUND = {
  title: 'Einladung abgelaufen.',
  body: `Ihr Einladungslink ist leider abgelaufen.`,
};
export const UPLOAD_SUCCESS = {
  title: 'Datei hochgeladen.',
  body: 'Die Datei wurde erfolgreich hochgeladen.',
};
export const REQUIRED_MISSING = {
  title: 'Fehlende Pflichtfelder.',
  body: 'Bitte füllen Sie erst alle Pflichtfelder aus.',
};
export const DELETE_SUCCESS = {
  title: 'Löschen erfolgreich.',
  body: '',
};
export const SAVE_SUCCESS = {
  title: 'Speichern erfolgreich.',
  body: '',
};
export const BOOKING_EXECUTE_SUCCESS = {
  title: 'Buchung abgeschlossen.',
  body: '',
};
export const BOOKING_REVERT_SUCCESS = {
  title: 'Buchung rückgängig gemacht.',
  body: '',
};
export const INQUIRY_EXECUTE_SUCCESS = {
  title: 'Avis abgeschlossen.',
  body: '',
};
export const SELECTION_FAILED = {
  title: 'Auswahl fehlgeschlagen.',
  body: 'Bei der Verarbeitung Ihrer Auswahl ist ein Fehler aufgetreten.',
};
export const CUSTOMER_EMPTY = {
  title: 'Fehler beim Laden des Kunden',
  body: 'Der gewählte Kunde konnte nicht geladen werden.',
};
export const INVALID_AMOUNT = {
  title: 'Fehlerhafte Mengenangabe',
  body: 'Die angegeben Menge kann nicht gebucht werden. Bitte prüfen Sie noch einmal Ihre Angaben.',
};
export const OUTGOING_AMOUNT_CHANGED = {
  title: 'Menge angepasst',
  body: 'Die auszulagernde Menge wurde automatisch aktualisiert.',
};
export const USER_WITH_EMAIL_ALREADY_EXISTS = {
  title: 'Email bereits in Benutzung',
  body: 'Die angegebene Email wird bereits für einen Zugang verwendet.',
};
export const MULTI_DELETE_SUCCESS = {
  title: 'Einträge erfolgreich gelöscht',
  body: 'Die gewählten Einträge wurden erfolgreich gelöscht.',
};
export const MULTI_DELETE_FAILED = {
  title: 'Fehler beim Löschen',
  body: `Beim Löschen der gewählten Einträge ist ein Fehler aufgetreten.`,
};
export const MULTI_DELETE_FAILED_WITH_AMOUNT = {
  title: 'Fehler beim Löschen',
  body: `Beim Löschen von ${MESSAGE_PLACEHOLDER} Einträgen ist ein Fehler aufgetreten.`,
};
export const DELETE_INQUIRY_FAILED = {
  title: 'Fehler beim Löschen',
  body: 'Beim Löschen des gewählten Lagerauftrages ist ein Fehler aufgetreten.',
};
export const DELETE_BOOKING_FAILED = {
  title: 'Fehler beim Löschen',
  body: 'Beim Löschen der gewählten Buchung ist ein Fehler aufgetreten.',
};
export const DELETE_ARTICLE_FAILED = {
  title: 'Fehler beim Löschen',
  body: 'Beim Löschen des gewählten Artikels ist ein Fehler aufgetreten.',
};
export const DELETE_INVOICE_FAILED = {
  title: 'Fehler beim Löschen',
  body: 'Beim Löschen der gewählten Rechnung ist ein Fehler aufgetreten.',
};
export const DELETE_GENERIC_FAILED = {
  title: 'Fehler beim Löschen',
  body: 'Beim Löschen des gewählten Eintrages ist ein Fehler aufgetreten.',
};

export const FILE_UPLOAD_FAILED_WITH_FILE_NAME = {
  title: 'Uploadfehler',
  body: `Beim Hochladen der Datei '${MESSAGE_PLACEHOLDER}' ist ein Fehler aufgetreten.`,
};

export const INQUIRY_CREATION_ERROR = {
  title: 'Fehler beim Erstellen',
  body: 'Beim Erstellen des Auftrages ist ein Fehler aufgetreten.',
};
export const INQUIRY_ITEM_CREATION_ERROR = {
  title: 'Fehler beim Erstellen',
  body: 'Beim Erstellen der Auftrags-Position ist ein Fehler aufgetreten.',
};
export const WAREHOUSE_NOT_FOUND = {
  title: 'Lager nicht gefunden',
  body: 'Das angegebene Lager konnte nicht gefunden werden.',
};
export const REBOOK_SUCCESS = {
  title: 'Umbuchung erfolgreich',
  body: '',
};
export const REBOOK_ERROR = {
  title: 'Umbuchung fehlgeschlagen',
  body: 'Bei der Umbuchung ist ein Fehler aufgetreten.',
};
export const FILE_DOWNLOAD_ERROR = {
  title: 'Fehler beim Herunterladen',
  body: 'Beim Herunterladen der Datei ist ein Fehler aufgetreten.',
};
export const INVOICE_CANCELLATION_ERROR = {
  title: 'Fehler beim Stornieren der Rechnung',
  body: 'Beim Stornieren der Rechnung ist ein Fehler aufgetreten.',
};
export const INVOICE_REMINDER_SEND_SUCCESS = {
  title: 'Zahlungserinnerungen versendet',
  body: 'Die Zahlungserinnerungen wurden versendet.',
};
export const RESET_TOKEN_NOT_FOUND = {
  title: 'Link abgelaufen.',
  body: `Ihr Bestätigungslink ist leider abgelaufen.`,
};
export const ERROR_PASSWORD_UPDATE = {
  title: 'Fehlerhafte Anfrage.',
  body: `Bei Ihrer Anfrage zum Zurücksetzen Ihres Passwortes ist ein Fehler aufgetreten.\n${MESSAGE_PLACEHOLDER}`,
};

export const BOOKING_REFERENCE_MISMATCH = {
  title: 'Referenz nicht im Lager',
  body: 'Die am auszubuchenden Artikel hinterlegte Referenz konnte im Lager nicht gefunden werden. Überprüfen Sie die Lagereinstellungen an der Buchung.',
};
