import {AfterViewInit, Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {AuthService} from '../../../@core/services/base/auth.service';
import {DataService} from '../../../@core/services/util/data.service';
import {UtilsService} from '../../../@core/services/util/utils.service';
import {UserService} from '../../../@core/services/base/user.service';
import {GroupService} from '../../../@core/services/base/group.service';
import {MenuService} from '../../../@core/services/base/menu.service';
import {MenuItemService} from '../../../@core/services/base/menu-item.service';

@Component({
  selector: 'ngx-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit, AfterViewInit {

  public loginName: string;
  public password: string;
  public isLogin: boolean = true;
  public resetSuccess: boolean = false;
  public email: string = '';
  private emailPattern = new RegExp('^(([^<>()\\[\\]\\\\.,;:\\s@"]+(\\.[^<>()\\[\\]\\\\.,;:\\s@"]+)*)|(".+"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$');

  constructor(
    private router: Router,
    private authService: AuthService,
    private dataService: DataService,
    private utilsService: UtilsService,
    private userService: UserService,
    private groupService: GroupService,
    private menuService: MenuService,
    private menuItemService: MenuItemService
  ) { }

  public ngOnInit(): void {
    this.isLogin = true;
    this.resetSuccess = false;
    this.disableLoadingSpinner();
  }

  public ngAfterViewInit() {
    if (history.state.authRedirect) {
      this.showErrToast();
      history.state.authRedirect = false;
    }
  }

  public async login(): Promise<void> {
    if (this.loginName == undefined || this.loginName == '' || this.password == undefined || this.password == '') {
      this.utilsService.showToast(UtilsService.TOAST_STATUS.warning, UtilsService.TOASTS.LOGIN_MISSING, 10000);
      this.password = '';
      return;
    }
    let login = await this.authService.login(this.loginName, this.password);
    if(login){
      let loadUser = await this.userService.loadSelf();
      if(loadUser){
        if(this.userService.getSelf().customerId == undefined || this.userService.getSelf().customerId.length < 0){
          await this.groupService.loadGroups();
          await this.menuService.loadMenus();
          await this.menuItemService.loadMenuItems();
          this.dataService.setCustomerStatus(false);
          await this.router.navigate(['/statistics']);
        }else {
          this.dataService.setCustomerStatus(true);
          await this.router.navigate(['/']);
        }
        this.menuService.menuUpdateCall.next();
      }else{
        this.utilsService.showToast(UtilsService.TOAST_STATUS.danger, UtilsService.TOASTS.LOAD_USER_FAILED, 0);
      }
    }else{
      this.utilsService.showToast(UtilsService.TOAST_STATUS.danger, UtilsService.TOASTS.LOGIN_FAILED, 0);
    }
  }

  private disableLoadingSpinner(): void {
    const el = document.getElementById('nb-global-spinner');
    if (el) {
      el.style['display'] = 'none';
    }
  }

  private showErrToast(): void {
    this.utilsService.showToast(UtilsService.TOAST_STATUS.danger, UtilsService.TOASTS.TOKEN_EXPIRED, 0);
  }

  public onKeypress(event: any): void {
    if (event.code === 'Enter') {
      this.login();
    }
  }

  public resetPassword(): void {
    this.isLogin = false;
  }

  public async doReset(): Promise<void> {
    if(this.emailValidation() == undefined || !this.emailValidation()) return;
    let success = await this.authService.resetPassword(this.email);
    if(success){
      this.resetSuccess = true;
    }
  }

  public backToLogin(): void {
    this.resetSuccess = false;
    this.isLogin = true;
  }

  public emailValidation(): boolean {
    if(this.email == '' || this.email == undefined) return undefined;
    return this.emailPattern.test(this.email);
  }

}
