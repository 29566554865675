import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {DataService} from '../util/data.service';
import * as ROUTES from '../../utils/ROUTES';
import {BasicService} from '../../utils/basic.service';
import {UtilsService} from '../util/utils.service';
import {MenuItem} from '../../models/base/MenuItem';

export class MenuDragNDropItem{
  item: MenuItem;
  children: MenuDragNDropItem[];
}

@Injectable({
  providedIn: 'root',
})
export class MenuItemService extends BasicService<MenuItem>{

  private loadMenuItemsInterval;
  private menuItems: MenuItem[];
  public itemsForNextSave: MenuItem[];

  constructor(
    private dataService: DataService,
    private http: HttpClient,
    private utils: UtilsService
  ) {
    super(dataService, http, ROUTES.MENU_ITEM_ROUTE);
    this.loadMenuItemsInterval = setInterval(async () => {
      if(!this.dataService.loggedInIsCustomer()) {
        this.loadMenuItems();
      }
    }, 30000);
    if(!this.dataService.loggedInIsCustomer()) {
      this.loadMenuItems();
    }
  }

  public async loadMenuItems(): Promise<void>{
    if (this.dataService.hasValidToken()) {
      let menuItems = await this.getAll();
      if (menuItems != undefined) {
        this.menuItems = menuItems;
      }
    }
  }

  public getMenuItems(): MenuItem[]{
    if(this.menuItems == undefined) return [];
    return this.utils.deepClone(this.menuItems);
  }

  public getByMenuId(menuId: string): MenuItem[]{
    return this.getMenuItems().filter(x => x.menuId == menuId);
  }

  public async saveItemsForMenu(menuId: string, items: MenuDragNDropItem[]): Promise<void> {
    let list = this.extractMenuItems(items, [], '');
    for (let item of list) {
      item.menuId = menuId;
      if(item.link == '%FOLDER_KEY%'){
        item.link = '';
        item.itemIsFolder = true;
      }
      await this.update(item);
    }
    for (let i of this.getByMenuId(menuId)) {
      if (list.find(x => x.id == i.id) == undefined) {
        await this.delete(i.id);
      }
    }
    await this.loadMenuItems();
  }

  private extractMenuItems(list: MenuDragNDropItem[], items: MenuItem[], parentId: string): MenuItem[]{
    for(let x of list){
      x.item.parentItemId = parentId;
      items.push(x.item);
      if(x.children != undefined && x.children.length > 0){
        items.concat(this.extractMenuItems(x.children, items, x.item.id));
      }
    }

    return items;
  }
}
