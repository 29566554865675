export const EDIT = '<i class="fas fa-pencil-alt"></i>';
export const EDIT_TOOLTIP = '<i class="fas fa-pencil-alt" data-toggle="tooltip" data-placement="top" title="Bearbeiten" data-trigger="hover"></i>';
export const DELETE = '<i class="fas fa-trash-alt"></i>';
export const DELETE_TOOLTIP = '<i class="fas fa-trash-alt" data-toggle="tooltip" data-placement="top" title="Löschen"></i>';
export const HISTORY = '<i class="fas fa-history"></i>';
export const USER = '<i class="fas fa-user"></i>';
export const CAMERA = '<i class="fas fa-camera"></i>';
export const DISABLE = '<i class="fas fa-ban"></i>';
export const ADD_CONTACT = '<i class="fas fa-address-book"></i>';
export const REGISTER = '<i class="fas fa-truck"></i>';
export const CATEGORIZE = '<i class="fas fa-clipboard-check"></i>';
export const PDF = '<i class="fas fa-file-pdf"></i>';
export const PDF_TOOLTIP = '<i class="fas fa-file-pdf" data-toggle="tooltip" data-placement="top" title="PDF erstellen" data-trigger="hover"></i>';
export const DOWNLOAD = '<i class="fas fa-download"></i>';
export const CLOUD_DOWNLOAD = '<img alt="download" src="/assets/icons/cloud_download.svg">';
export const ZOOM_IN = '<i class="fas fa-search-plus"></i>';
export const ZOOM_IN_THIN = '<img alt="zoom in" src="/assets/icons/zoom_in_thin.svg">';
export const ZOOM_OUT = '<i class="fas fa-search-minus"></i>';
export const ZOOM_OUT_THIN = '<img alt="zoom out" src="/assets/icons/zoom_out_thin.svg">';
export const CLOSE = '<i class="fas fa-times"></i>';
export const CLOSE_THIN = '<img alt="close" src="/assets/icons/close_thin.svg">';
export const ADD = '<i class="fas fa-plus"></i>';
export const PAPER_PLANE = '<i class="far fa-paper-plane"></i>';
export const INFO = '<i class="fas fa-info-circle"></i>';
export const MINUS = '<i class="fas fa-minus"></i>';
export const PLUS = '<i class="fas fa-plus"></i>';
export const BOXES = '<i class="fas fa-boxes"></i>';
export const ANGLE_DOUBLE_RIGHT = '<i class="fas fa-angle-double-right"></i>';
export const ANGLE_DOUBLE_RIGHT_TOOLTIP = '<i class="fas fa-angle-double-right" data-toggle="tooltip" data-placement="top" title="In Buchung überführen"></i>';
export const STAMP_TOOLTIP_EXECUTE = '<i class="fas fa-stamp" data-toggle="tooltip" data-placement="top" title="Buchung abschließen"></i>';
export const STAMP_TOOLTIP_REVERT = '<i class="fas fa-angle-double-left" data-toggle="tooltip" data-placement="top" title="Buchung rückgängig machen"></i>';
export const SORT_UP = '<i class="fas fa-sort-up"></i>';
export const SORT_DOWN = '<i class="fas fa-sort-down"></i>';
export const ANGLE_UP = '<i class="fas fa-angle-up"></i>';
export const ANGLE_DOWN = '<i class="fas fa-angle-down"></i>';
export const CUSTOMER_RECEIVER_TOOLTIP = '<i class="fas fa-mail-bulk" data-toggle="tooltip" data-placement="top" title="Emails verwalten"></i>';
export const CUSTOMER_USERS_TOOLTIP = '<i class="fas fa-users" data-toggle="tooltip" data-placement="top" title="Zugänge verwalten"></i>';
export const CUSTOMER_DISABLE_TOOLTIP = '<i class="fas fa-lock" data-toggle="tooltip" data-placement="top" title="Sperrung aufheben"></i>';
export const CUSTOMER_ENABLE_TOOLTIP = '<i class="fas fa-lock-open" data-toggle="tooltip" data-placement="top" title="Kundenzugänge sperren"></i>';
export const CUSTOMER_ARTICLE_LIST_TOOLTIP = '<i class="fas fa-file-alt" data-toggle="tooltip" data-placement="top" title="Artikelliste herunterladen"></i>';
export const COPY = '<i class="fas fa-copy" data-toggle="tooltip" data-placement="top" title="Kopieren"></i>';
export const SPLIT = '<i class="fas fa-clone" data-toggle="tooltip" data-placement="top" title="Aufteilen"></i>';

export const FILE_TYPES = {
  DEFAULT: 'fas fa-file-pdf',
  FOLDER: '<i class="fas fa-folder"></i>',
  PDF: '<i class="far fa-file-pdf"></i>',
};
