<div class="topControls d-flex justify-content-between" [ngClass]="{'active': topControlsActive}">
  <div class="leftControls">
    <!--
    <div class="controls-close" [innerHtml]="icons.CLOSE_THIN" (click)="close()"></div>
    -->
    <div class="controls-close" (click)="close()">
      <ngx-icon [icon]="'CLOSE_THIN'" [color]="'white'" [rotateHover]="true"></ngx-icon>
    </div>
  </div>
  <div class="centerControls d-flex">
    <div class="zoom" (click)="zoomUp()">
      <ngx-icon [icon]="'ZOOM_IN_THIN'" [color]="'white'" [zoomHover]="true"></ngx-icon></div>
    <div class="zoom" (click)="zoomDown()">
      <ngx-icon [icon]="'ZOOM_OUT_THIN'" [color]="'white'" [zoomHover]="true"></ngx-icon></div>
  </div>
  <div class="rightControls">
    <div class="controls-download" (click)="download()">
      <ngx-icon [icon]="'CLOUD_DOWNLOAD'" [color]="'white'" [zoomHover]="true"></ngx-icon></div>
  </div>
</div>
<div class="viewerWrapper">
  <pdf-viewer
    (click)="backDropClick($event)"
    [src]="pdfSrc"
    [render-text]="true"
    [original-size]="true"
    [external-link-target]="'blank'"
    [zoom]="zoom"
    [zoom-scale]="'page-width'"
    [(page)]="page"
    (pages-initialized)="initDone($event)"
    style="width: 100%; height: 100%"
  ></pdf-viewer>
</div>
<div class="pageNumber">
  Seite: {{page}} / {{maxPages}}
</div>
