import {Component, Input, OnInit} from '@angular/core';
import {NbDialogRef} from '@nebular/theme';

@Component({
  selector: 'ngx-image-lightbox',
  templateUrl: './image-lightbox.component.html',
  styleUrls: ['./image-lightbox.component.scss'],
})
export class ImageLightboxComponent implements OnInit {

  @Input() public src: any;
  @Input() public imageDescription: string = '';

  constructor(
    private nbDialogRef: NbDialogRef<any>
    ) {}

  ngOnInit(): void {
    if(this.src == undefined){
      this.close();
    }
  }

  public close(): void{
    this.nbDialogRef.close();
  }

}
