import {Injectable} from '@angular/core';
import {BasicService} from '../../utils/basic.service';
import {Setting} from '../../models/base/Setting';
import * as ROUTES from '../../utils/ROUTES';
import {DataService} from '../util/data.service';
import {HttpClient} from '@angular/common/http';
import {UtilsService} from '../util/utils.service';

@Injectable({
  providedIn: 'root',
})
export class SettingService extends BasicService<Setting> {

  public static CUSTOMER_TEMPLATE_DOCMETA_ID_KEY: string = 'setting.customer.articletemplate.docmetaid';
  public static HOLIDAY_SETTING_KEY: string = 'setting.system.holidays';

  private settings: Setting[] = [];
  private loadSettingsInterval;

  constructor(
    private dataService: DataService,
    private http: HttpClient,
    private utils: UtilsService
  ) {
    super(dataService, http, ROUTES.SETTING_ROUTE);
    this.loadSettingsInterval = setInterval(async () => {
      this.loadSettings();
    }, 60000);
    this.loadSettings();
  }

  public async loadSettings(): Promise<void> {
    if (this.dataService.hasValidToken()) {
      let settings = await this.getAll();
      if (settings != undefined) {
        this.settings = settings;
      }
    }
  }

  public async create(obj: Setting): Promise<Setting> {
    let res = await super.create(obj);
    await this.loadSettings();
    return res;
  }

  public async update(obj: Setting): Promise<Setting> {
    let res = await super.update(obj);
    await this.loadSettings();
    return res;
  }

  public async delete(id: string): Promise<boolean> {
    let res = await super.delete(id);
    await this.loadSettings();
    return res;
  }

  public getSettings(): Setting[] {
    return this.utils.deepClone(this.settings);
  }

  public async getByKey(key: string): Promise<Setting | undefined>{
    if(this.settings != undefined && this.settings.find(x => x.key == key) != undefined){
      return this.settings.find(x => x.key == key);
    }
    let query = `?filter=${btoa(`key=${key}`)}`;
    let settings: Setting[] = await this.getAll(query);
    if(settings != undefined && settings.find(x => x.key == key) != undefined){
      return settings.find(x => x.key == key);
    }
    return undefined;
  }

  public async getHolidayDates(): Promise<Date[]> {
    let dates: Date[] = [];
    let holidaySetting = await this.getByKey(SettingService.HOLIDAY_SETTING_KEY);
    if(holidaySetting != undefined){
      let holidays = JSON.parse(holidaySetting.value);
      if(holidays != undefined && Array.isArray(holidays)){
        for(let h of holidays){
          let split = h.split('.');
          let d;
          if(split[2].includes('x')){
            d = new Date(`${split[1]}.${split[0]}.2000`);
          }else{
            d = new Date(`${split[1]}.${split[0]}.${split[2]}`);
          }
          if(d != undefined){
            dates.push(d);
          }
        }
      }
    }
    return dates;
  }

}
