import {EventEmitter, Injectable} from '@angular/core';
import {NbComponentStatus, NbDialogService, NbGlobalPhysicalPosition, NbToastrService} from '@nebular/theme';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import * as TOASTS from '../../utils/TOASTS';
import cloneDeep from "lodash/cloneDeep";
import {PdfViewerComponent} from '../../../components/base/pdf-viewer/pdf-viewer.component';
import {ImageLightboxComponent} from '../../../components/base/image-lightbox/image-lightbox.component';
import {DataService} from './data.service';
import * as ROUTES from '../../utils/ROUTES';
import {GenericResponse} from '../../models/util/GenericResponse';
import {BACKEND_URL} from '../../utils/ROUTES';
import {EntityEditComponent, EntityEditConfig} from '../../../components/base/entity-edit/entity-edit.component';
import {ConfirmComponent} from '../../../components/base/confirm/confirm.component';
import {EntityEditModalComponent} from '../../../components/base/entity-edit-modal/entity-edit-modal.component';
import {BasicService} from '../../utils/basic.service';
import {NbDialogRef} from '@nebular/theme/components/dialog/dialog-ref';
import {Router, NavigationStart, Event as NavigationEvent} from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class UtilsService {

  public static TOASTS = TOASTS;
  public static TOAST_STATUS = {
    primary: 0,
    success: 1,
    info: 2,
    warning: 3,
    danger: 4,
  };
  private types: NbComponentStatus[] = [
    'primary',
    'success',
    'info',
    'warning',
    'danger',
  ];
  private defaultSuccessDuration = 5000;
  private hasIcon = true;
  private position = NbGlobalPhysicalPosition.TOP_RIGHT;
  private preventDuplicates = false;
  private logErrors = true;
  private openModal: NbDialogRef<any>;
  public spinnerEvent: EventEmitter<boolean> = new EventEmitter<boolean>();
  public spinnerTitle = 'Lädt...';

  constructor(
    private toastrService: NbToastrService,
    private dialogService: NbDialogService,
    private http: HttpClient,
    private dataService: DataService,
    private router: Router
  ) {
    this.openModal = undefined;
    this.router.events.subscribe((e: NavigationEvent) => {
      if(e instanceof NavigationStart){
        if(this.openModal != undefined){
          this.closeEditModal();
        }
      }
    });
  }

  public showToast(type: number, message: any, durationInMs: number = -1, additionalInfo: any = '') {
    if (durationInMs === -1) {
      if (type === 1){
        durationInMs = this.defaultSuccessDuration;
      } else {
        durationInMs = 0;
      }
    }
    const config = {
      status: this.types[type],
      destroyByClick: true,
      duration: durationInMs,
      hasIcon: this.hasIcon,
      position: this.position,
      preventDuplicates: this.preventDuplicates,
    };

    if(additionalInfo instanceof HttpErrorResponse){
      if (additionalInfo.error != undefined && additionalInfo.error.errors != undefined && additionalInfo.error.errors[0]) {
        additionalInfo = additionalInfo.error.errors[0];
      } else {
        additionalInfo = '';
      }
    }

    const titleContent = message.title ? `${message.title}` : '';
    const bodyContent = message.body ? `${message.body.replace(TOASTS.MESSAGE_PLACEHOLDER,additionalInfo)}` : '';

    this.toastrService.show(
      bodyContent,
      titleContent,
      config
    );
  }

  public downLoadFile(data: any, name: string): void{
    let blob = new Blob([data], { type: 'application/octet-stream'});
    let url = window.URL.createObjectURL(blob);
    let link = document.createElement('a');
    link.setAttribute('type','hidden');
    link.href = url;
    link.download = name;
    document.body.appendChild(link);
    link.click();
    link.remove();
  }

  public async logError(...params: any[]): Promise<void> {
    if (this.logErrors) {
      for (const x of params) {
        // tslint:disable-next-line:no-console
        console.log(x);
        if (x.error != undefined && x.error instanceof Blob) {
          await x.error.text().then(z => {
            if(z != undefined && z.length > 0){
              try{
                // tslint:disable-next-line:no-console
                console.log('parsed error blob:',JSON.parse(z));
              }catch (e){
                // tslint:disable-next-line:no-console
                console.log('blob error parse failed');
              }
            }
          });
        }
      }
    }
  }

  public formatDate(time: number | Date): string{
    let date;
    if(time instanceof Date) {
      date = time;
    }else{
      date = new Date(time);
    }
    if(date == undefined) return 'N/A';
    let month = date.getMonth() + 1;
    let day = date.getDate();
    let year = date.getFullYear();
    return `${day < 10 ? '0' + day : day}.${month < 10 ? '0' + month : month}.${year}`;
  }

  public formatTime(time: number | Date, showSeconds: boolean = false): string{
    let date;
    let seconds = '';
    if(time instanceof Date) {
      date = time;
    }else{
      date = new Date(time);
    }
    if(date == undefined) return 'N/A';
    let hours = date.getHours();
    let minutes = date.getMinutes();
    if(showSeconds){
      seconds = `:${date.getSeconds()}`;
    }
    return `${hours < 10 ? '0' + hours : hours}:${minutes < 10 ? '0' + minutes : minutes}${seconds}`;
  }

  public formatDateTime(time: number | Date, showSeconds: boolean = false): string{
    return `${this.formatDate(time)} ${this.formatTime(time, showSeconds)}`;
  }

  public deepClone(array: any[]): any[]{
    return cloneDeep(array);
  }

  public viewPdf(src: string | Blob, fileName: string, closeOnBackDrop: boolean = true): void{
    this.dialogService.open(PdfViewerComponent,{
      context: {
        pdfSrcRaw: src,
        fileName,
        closeOnBackDrop,
      },
    });
  }

  public lightboxImage(src: any, imageDescription: string = '', closeOnBackdropClick: boolean = true): void{
    this.dialogService.open(ImageLightboxComponent, {
      dialogClass: 'indev-lightbox',
      closeOnBackdropClick,
      context: {
        src,
        imageDescription,
      },
    });
  }

  public async getCollectionFromURL(url: string): Promise<any[]>{
    let response = [];
    await this.http.get<GenericResponse<any[]>>(`${ROUTES.BACKEND_URL}${url.startsWith('/') ? '' : '/'}${url}`, this.dataService.getHttpOptions()).toPromise()
      .then((res: GenericResponse<any[]>) => {
        if(res != undefined && res.body != undefined && res.status != undefined && res.status == 200 && Array.isArray(res.body)){
          response = res.body;
        }
      });
    return response;
  }

  public async openEditModal(config: EntityEditConfig, entity: any, service: BasicService<any>, isEdit: boolean, events: EventEmitter<{ reload: boolean, customActions: string[] }>, enableCopy: boolean = false, useCustomComponent = 'custom', specificData = [], customCssClass: string = 'slide-in'): Promise<void> {
    if (this.openModal != undefined) {
      const confirm = this.dialogService.open(ConfirmComponent, {
        closeOnBackdropClick: false,
        context: {
          headline: 'Es ist bereits ein Fenster offen',
          body: 'Möchten Sie das bereits geöffnete Fenster schließen und das neue öffnen? (Eventuelle Änderungen in dem alten Fenster werden nicht gespeichert!)',
        },
      });
      await confirm.onClose.toPromise()
        .then(res => {
          if(res != undefined && res.delete){
            if(this.openModal['overlayRef'] != undefined && this.openModal['overlayRef']['_pane'] != undefined && this.openModal['overlayRef']['_pane']['classList'] != undefined){
              (this.openModal['overlayRef']['_pane']['classList'] as DOMTokenList).remove('slide-finish');
            }
            setTimeout(() => {
              this.openModal.onClose.toPromise().then(() => {
                this.openModal = undefined;
                this.editModal(config, entity, service, isEdit, events, enableCopy, useCustomComponent, specificData, customCssClass);
              });
              this.openModal.close();
            },700);
          }
        });
    }else{
      this.editModal(config, entity, service, isEdit, events, enableCopy, useCustomComponent, specificData, customCssClass);
    }
  }

  private editModal(config: EntityEditConfig, entity: any, service: BasicService<any>, isEdit: boolean, events: EventEmitter<{ reload: boolean, customActions: string[] }>, enableCopy: boolean, useCustomComponent: string, specificData: any[], customCssClass: string = 'slide-in'): void{
    this.openModal = this.dialogService.open(EntityEditModalComponent, {
      dialogClass: customCssClass,
      hasBackdrop: false,
      hasScroll: true,
      autoFocus: false,
      context: {
        config,
        entity,
        service,
        isEdit,
        events,
        useCustomComponent,
        specificData,
        enableCopy,
      },
    });
    this.openModal.onClose.toPromise().then(() => {
      this.openModal = undefined;
    });
    setTimeout(() => {
      if(this.openModal['overlayRef'] != undefined && this.openModal['overlayRef']['_pane'] != undefined && this.openModal['overlayRef']['_pane']['classList'] != undefined){
        (this.openModal['overlayRef']['_pane']['classList'] as DOMTokenList).add('slide-finish');
      }
    }, 100);
  }

  public closeEditModal(): void{
    if(this.openModal['overlayRef'] != undefined && this.openModal['overlayRef']['_pane'] != undefined && this.openModal['overlayRef']['_pane']['classList'] != undefined){
      (this.openModal['overlayRef']['_pane']['classList'] as DOMTokenList).remove('slide-finish');
    }
    setTimeout(() => {
      this.openModal.close();
      this.openModal = undefined;
    }, 500);
  }

  public getDateInputString(d: Date): string{
    return `${d.getFullYear()}-${(d.getMonth() + 1) < 10 ? '0' + (d.getMonth() + 1) : d.getMonth() + 1}-${d.getDate() < 10 ? '0' + d.getDate() : d.getDate()}`;
  }

  /**
   * try to format given number to local default.
   * @param value value to be formatted
   * @param fractionDigits max amount of fraction digits
   * @param minFractions max amount of fraction digits
   * @returns formatted number or empty string if formatting failed
   */
  public formatNumber(value: number, fractionDigits: number = 3, minFractions: number = 1): string {
    if(value != undefined){
      // try to parse string if for some reason as string was given. (might happen in smart-tables)
      if(typeof value == 'string'){
        try{
          value = parseFloat(value);
        }catch (e){
          return '';
        }
      }
      if(typeof value == 'number'){
        return parseFloat(value.toFixed(fractionDigits)).toLocaleString(undefined, {
          minimumFractionDigits: minFractions,
          maximumFractionDigits: fractionDigits,
        });
      }
    }
    return '';
  }

  /**
   * execute any given function (up to 3 args) on any given object with given payload. Response is handled via events to allow simultaneous requests
   *
   *
   * usage examples:
   * ````
   *     let queueResponse: EventEmitter<{entry: any, response: any}[]> = new EventEmitter<{entry: any, response: any}[]>();
   *     queueResponse.subscribe(res => this.handleResponse(res));
   *     this.utils.requestQueue(this.service, 'doStuff', [[1,'asdf'], [2,'test']], queueResponse, false);
   * ````
   * ````
   *     let queueResponse: EventEmitter<{entry: any, response: any}[]> = new EventEmitter<{entry: any, response: any}[]>();
   *     queueResponse.subscribe(res => this.handleResponse(res));
   *     this.utils.requestQueue(this.service, 'doStuff', [1,2,3], queueResponse);
   * ````
   * @param executorInstance service or other object whose function will be called
   * @param executionFunctionName name of the function which will be called from the given instance
   * @param executionPayload list of arguments. this will be iterated.
   * @param callBack EventEmitter will be triggered once the number of gathered responses >= length of payload
   * @param singleArg weather the given function takes 1 argument only or multiple. If false, each payload entry has to be an array of arguments
   */
  public requestQueue(executorInstance: any, executionFunctionName: string, executionPayload: any[],  callBack: EventEmitter<{entry: any, response: any}[]>, singleArg = true): void{
    // abort if given function does not exist or is not a function
    if(executorInstance[executionFunctionName] == undefined || typeof executorInstance[executionFunctionName] != 'function'){
      return undefined;
    }
    let count = 0;
    let responses:{entry: any, response: any}[] = [];
    let sentOnce: boolean = false;

    // "queue" or response-collector
    const queue: EventEmitter<{entry: any, response: any}> = new EventEmitter<{entry: any, response: any}>();

    // gather all responses and return this collection to given callBack event.
    queue.subscribe((res: {entry: any, response: any}) => {
      count++;
      responses.push(res);
      if(count >= executionPayload.length){
        // only respond once in case of a counting mishap
        if(!sentOnce){
          sentOnce = true;
          callBack.emit(responses);
        }
      }
    });

    // start execution for all payload entities & send them into queue
    for(let e of executionPayload){
      if(!singleArg && Array.isArray(e)){
        executorInstance[executionFunctionName](...e).then((res) => queue.next({entry: e, response: res}));
      }else{
        executorInstance[executionFunctionName](e).then((res) => queue.next({entry: e, response: res}));
      }
    }

  }

  public startSpinner(title?: string): void {
    if(title != undefined){
      this.spinnerTitle = title;
    }else{
      this.spinnerTitle = 'Lädt...';
    }
    this.spinnerEvent.next(true);
  }
  public stopSpinner(): void {
    this.spinnerEvent.next(false);
  }

}
