import {HttpErrorResponse} from "@angular/common/http";
import {Injectable} from "@angular/core";
import {CanActivate, Router} from "@angular/router";
import {Observable} from "rxjs";
import {AuthService} from '../base/auth.service';
import {DataService} from '../util/data.service';
import {GenericResponse} from '../../models/util/GenericResponse';
import {MenuService} from '../base/menu.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {

  private session;
  private tokenCheckIntervalInMs = 15000;

  constructor(
    private authService: AuthService,
    private dataService: DataService,
    private router: Router,
    private menuService: MenuService
  ) {
  }

  public canActivate(): Observable<boolean> {
    if (this.dataService.offlineMode) {
      return new Observable<boolean>((observer) => {
        observer.next(true);
        observer.complete();
      });
    }

    if (!this.dataService.hasValidToken()) {
      this.router.navigate(['/login']);
      this.stopLoginSession();
      return new Observable<boolean>((observer) => {
        observer.next(false);
        observer.complete();
      });
    }

    return new Observable<boolean>((observer) => {
      this.authService.validateToken().subscribe({
        error: (err: HttpErrorResponse) => {
          this.router.navigate(['/login']);
          this.stopLoginSession();
          observer.next(false);
        },
        next: (response: GenericResponse<any>) => {
          if (response.status === 200) {
            this.dataService.setToken(response.token);
            this.authService.loggedInEvent.next();
            this.menuService.menuUpdateCall.next();
            observer.next(true);
          } else {
            this.router.navigate(['/login']);
            this.stopLoginSession();
            observer.next(false);
          }
        },
        complete: () => {
          observer.complete();
        },
      });
    });
  }

  public startLoginSession(): void {
    this.checkToken();
  }

  public stopLoginSession(): void {
    if (this.session != undefined) {
      clearInterval(this.session);
    }
  }

  private checkToken(): void {
    this.session = setInterval(async () => {
      this.canActivate().subscribe();
    }, this.tokenCheckIntervalInMs);
  }
}
