<nb-card>
  <nb-card-header>
    {{deactivate ? headlineDeactivate : headline}}
  </nb-card-header>
  <nb-card-body *ngIf="!htmlBody">
    {{deactivate ? bodyDeactivate : body}}
  </nb-card-body>
  <nb-card-body *ngIf="htmlBody" [innerHtml]="deactivate ? bodyDeactivate : body">
  </nb-card-body>
  <nb-card-footer>
    <button nbButton status="danger" (click)="dismiss()" style="float: right">Abbrechen</button>
    <button nbButton (click)="confirm()">Ok</button>
  </nb-card-footer>
</nb-card>
