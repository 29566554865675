import {Injectable} from '@angular/core';
import {HttpHeaders} from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class DataService {

  private token: string = '';

  public offlineMode: boolean = false;
  private isCustomer: boolean = false;

  constructor() {
    this.setToken(localStorage.getItem('token'));
  }

  public setCustomerStatus(status: boolean): void{
    this.isCustomer = status;
  }

  public loggedInIsCustomer(): boolean{
    return this.isCustomer;
  }


  public getHttpOptions(token?: string): object {
    let t;
    if (token != undefined) {
      t = token;
    } else if (this.getToken() != undefined && this.getToken() != '') {
      t = this.getToken();
    } else {
      return this.getHttpOptionsNoToken();
    }
    return {
      headers: new HttpHeaders({'Authorization': 'Bearer ' + t, 'Access-Control-Allow-Origin': '*',}),
    };
  }

  public getHttpOptionsWithXAuth(token?: string): object {
    let t;
    if (token != undefined) {
      t = token;
    } else if (this.getToken() != undefined && this.getToken() != '') {
      t = this.getToken();
    } else {
      return this.getHttpOptionsNoToken();
    }
    return {
      headers: new HttpHeaders({'Authorization': 'Bearer ' + t, 'X-Auth-Token': t, 'Access-Control-Allow-Origin': '*',}),
    };
  }

  public getHttpOptionsBlob(token?: string): object {
    let h = this.getHttpOptions(token);
    h['responseType'] = 'blob';
    h['observe'] = 'response';
    (h['headers'] as HttpHeaders).set('timeout', '600');
    return h;
  }

  public getHttpOptionsBlobWithXAuth(token?: string): object {
    let h = this.getHttpOptionsWithXAuth(token);
    h['responseType'] = 'blob';
    h['observe'] = 'response';
    (h['headers'] as HttpHeaders).set('timeout', '600');
    return h;
  }

  public getHttpOptionsNoToken(): object {
    return {
      headers: new HttpHeaders({'Access-Control-Allow-Origin': '*',}),
    };
  }

  public setToken(token: string): void {
    if(token == 'null' || token == 'undefined') token = '';
    if(token != undefined && token.startsWith('Bearer ')) token = token.replace('Bearer ','');
    localStorage.setItem('token', token);
    this.token = token;
  }

  public clearData(): void {
    localStorage.clear();
    this.token = undefined;
  }

  public getToken(): string {
    if (this.token == undefined || this.token == '') {
      return localStorage.getItem('token');
    } else {
      return this.token;
    }
  }

  public hasValidToken(): boolean{
    return this.getToken() != undefined && this.getToken() != '';
  }

}
