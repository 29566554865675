import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {UtilsService} from '../../../@core/services/util/utils.service';
import {AuthService} from '../../../@core/services/base/auth.service';
import {UserService} from '../../../@core/services/base/user.service';
import {GroupService} from '../../../@core/services/base/group.service';
import {MenuService} from '../../../@core/services/base/menu.service';
import {MenuItemService} from '../../../@core/services/base/menu-item.service';
import {DataService} from '../../../@core/services/util/data.service';

@Component({
  selector: 'ngx-invite',
  templateUrl: './invite.component.html',
  styleUrls: ['./invite.component.scss'],
})
export class InviteComponent implements OnInit {

  public password: string;
  public password2: string;
  public passwordNotValid: boolean = false;

  private registrationToken: string;
  private routeListener: any;

  constructor(
    private route: ActivatedRoute,
    private authService: AuthService,
    private router: Router,
    private utils: UtilsService,
    private utilsService: UtilsService,
    private userService: UserService,
    private groupService: GroupService,
    private menuService: MenuService,
    private menuItemService: MenuItemService,
    private dataService: DataService
  ) { }

  async ngOnInit(): Promise<void> {
    this.routeListener = this.route.params.subscribe(async params => {
      this.registrationToken = params['token'];
      if (this.registrationToken == undefined) {
        this.utils.showToast(UtilsService.TOAST_STATUS.danger, UtilsService.TOASTS.TOKEN_NOT_FOUND);
        this.router.navigate(['/login']);
      }
    });
  }

  public async validateAndSend(): Promise<void> {
    if (this.password == undefined || this.password.length == 0 || this.password2 == undefined || this.password2.length == 0 || this.password != this.password2) {
      this.passwordNotValid = true;
    } else {
      let success = await this.authService.sendPasswordForInvite(this.registrationToken, this.password);
      if (success) {
        let loadUser = await this.userService.loadSelf();
        if (loadUser) {
          if (this.userService.getSelf().customerId == undefined || this.userService.getSelf().customerId.length < 0) {
            await this.groupService.loadGroups();
            await this.menuService.loadMenus();
            await this.menuItemService.loadMenuItems();
            this.dataService.setCustomerStatus(false);
          } else {
            this.dataService.setCustomerStatus(true);
          }
          await this.router.navigate(['/']);
          this.menuService.menuUpdateCall.next();
        } else {
          this.utilsService.showToast(UtilsService.TOAST_STATUS.danger, UtilsService.TOASTS.LOAD_USER_FAILED, 0);
        }
      }
    }
  }

  public onKeypress(event: any): void {
    if (event.code === 'Enter') {
      this.validateAndSend();
    }
  }
}
