<div class="header-container" [ngClass]="{'compacted' : sidebarCompacted}">
  <div class="logo-container">
    <a (click)="toggleSidebar()" href="#" class="sidebar-toggle">
      <nb-icon icon="menu-2-outline"></nb-icon>
    </a>
    <a class="logo d-flex align-items-center" href="/" (click)="navigateHome()" *ngIf="!sidebarCompacted">
      <!--
      <div *ngIf="logoBase64String == undefined">indev-<span>crm</span></div>
      -->
      <img [src]="logoBase64String" *ngIf="logoBase64String != undefined" class="logoImage" alt="company-logo">
      <span class="logo-subtitle" *ngIf="logoSubtitle != undefined && logoSubtitle.length > 0">{{logoSubtitle}}</span>
    </a>
  </div>
  <!--
  <nb-select [selected]="currentTheme" (selectedChange)="changeTheme($event)" status="primary">
    <nb-option *ngFor="let theme of themes" [value]="theme.value"> {{ theme.name }}</nb-option>
  </nb-select>
  -->
</div>

<div class="header-container">
  <div class="spacer"></div>
  <div class="open-invoice-disclaimer" *ngIf="userIsCustomer && openInvoices > 0">Es gibt aktuell {{ openInvoices }} offene Rechnungen!</div>
  <nb-actions size="small">
    <!--
      <nb-action class="control-item">
        <nb-search type="rotate-layout"></nb-search>
      </nb-action>
      -->
    <!--
    <nb-action class="control-item" icon="email-outline"></nb-action>
    <nb-action class="control-item" icon="bell-outline"></nb-action>
    -->
    <nb-action class="user-action" *nbIsGranted="['view', 'user']">
      <nb-user [nbContextMenu]="userMenu"
               nbContextMenuTag="user-menu"
               [onlyPicture]="userPictureOnly"
               [picture]="user?.profileImage"
               [name]="user?.email">
      </nb-user>
    </nb-action>
  </nb-actions>
</div>
