<div
  class="indev-icon"
  [style]="{
  '--icon-color': normalColor,
  '--icon-color-hover': hoverColor,
  '--icon-image-color-filter': imageColorFilter,
  '--icon-image-color-filter-hover': imageColorFilterHover
  }"
  [innerHtml]="icons[icon]"
  [ngClass]="{'spin-hover': rotateHover, 'zoom-hover': zoomHover}"
></div>
