import {NbMenuItem} from '@nebular/theme';

export const MENU_ITEMS: NbMenuItem[] = [];
export const MENU_ITEMS_FALLBACK: NbMenuItem[] = [
  {
    title: 'Home',
    icon: 'home-outline',
    link: '/',
    home: true,
  },
  {
    title: 'Benutzer',
    icon: 'settings-outline',
    link: '/users',
  },
  {
    title: 'Einstellungen',
    icon: 'settings-outline',
    link: '/settings',
  },
  {
    title: 'Rollen',
    icon: 'settings-outline',
    link: '/roles',
  },
  {
    title: 'Gruppen',
    icon: 'settings-outline',
    link: '/groups',
  },
  {
    title: 'Menüs',
    icon: 'settings-outline',
    link: '/menus',
  },
  {
    title: 'Dokumente',
    icon: 'folder-outline',
    link: '/documents',
  },
  {
    title: 'Dokument-Typen',
    icon: 'file-text-outline',
    link: '/doctypes',
  },
  {
    title: 'Kunden',
    icon: 'file-text-outline',
    link: '/customers',
  },
  {
    title: 'Kunden-Stages',
    icon: 'file-text-outline',
    link: '/customer-stages',
  },
  {
    title: 'Buchungen',
    icon: 'file-text-outline',
    link: '/warehouse-bookings',
  },
  {
    title: 'Artikel',
    icon: 'file-text-outline',
    link: '/articles',
  },
  {
    title: 'Lager',
    icon: 'file-text-outline',
    link: '/warehouses',
  },
  {
    title: 'Kunden-Artikel',
    icon: 'file-text-outline',
    link: '/customer-articles',
  },
  {
    title: 'Rechnungsbedingungen',
    icon: 'file-text-outline',
    link: '/billing-agreements',
  },
  {
    title: 'Zahlingsbedingungen',
    icon: 'file-text-outline',
    link: '/payment-agreements',
  },
  {
    title: 'Dienstleistungen',
    icon: 'file-text-outline',
    link: '/warehouse-services',
  },
  {
    title: 'getätigte Dienstleistungen',
    icon: 'file-text-outline',
    link: '/warehouse-service-entries',
  },
  {
    title: 'Anfragen',
    icon: 'file-text-outline',
    link: '/customer-inquiries',
  },
  {
    title: 'Jobs',
    icon: 'file-text-outline',
    link: '/job-list',
  },
  {
    title: 'Lagerartikel',
    icon: 'file-text-outline',
    link: '/warehouse-specific-list',
  },
];
