import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError, retry} from 'rxjs/operators';
import {UtilsService} from '../services/util/utils.service';
import {Injectable} from '@angular/core';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor{

  constructor(
    private utils: UtilsService
  ) {
  }
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req)
      .pipe(
        retry(1),
        catchError((error: HttpErrorResponse) => {
          let errorMessage = '';
          // tslint:disable:no-console
          console.log(error);
          if(error.error instanceof ErrorEvent){
            errorMessage = error.error.message;
          }else {
            errorMessage = error.message;
          }
          if(error.error != undefined && error.error.errors != undefined && Array.isArray(error.error.errors) && error.error.errors.length > 0){
            for(let err of error.error.errors){
              errorMessage += ' | ' + err;
            }
          }
          if(error.status == 404){
            this.utils.showToast(UtilsService.TOAST_STATUS.warning, UtilsService.TOASTS.CONTENT_NOT_FOUND, 0, `\nFehlermeldung:\n${errorMessage}`);
          }
          if(error.status == 403){
            if(error.error.errors != undefined && error.error.errors.length > 0 && error.error.errors[0].includes('wrong credentials')){
              this.utils.showToast(UtilsService.TOAST_STATUS.danger, UtilsService.TOASTS.LOGIN_FAILED, 0);
            } else {
              let additionalInfo = '';
              if(error.error != undefined && error.error.path != undefined && error.error.path.length > 0) additionalInfo = `\nAngefragte Resource: ${error.error.path}`;
              this.utils.showToast(UtilsService.TOAST_STATUS.warning, UtilsService.TOASTS.INSUFFICIENT_PERMISSION, 15000, additionalInfo);
            }
          }
          if(error.status == 500){
            let additionalInfo = '';
            if(error.error != undefined && error.error.path != undefined && error.error.path.length > 0) additionalInfo = `\nAngefragte Resource: ${error.error.path}`;
            errorMessage = additionalInfo + `\nFehlermeldung:\n${errorMessage}`;
            this.utils.showToast(UtilsService.TOAST_STATUS.danger, UtilsService.TOASTS.INTERNAL_SERVER_ERROR, 15000, errorMessage);
          }
          return throwError(errorMessage);
        })
      );
  }
}
