<nb-layout>
  <nb-layout-column class="p-0">
    <div class="bg">
      <div class="container-fluid h-100">

        <div class="row h-100">
          <div class="col-4 col-md-8 bg-image position-relative">
            <div class="caption-wrapper">
              <div class="caption d-none d-md-block">we are mega-log</div>
              <div class="caption big-caption d-none d-md-block">your partner for<br>future business</div>
            </div>
            <div class="overlay"></div>
          </div>
          <div class="col-8 col-md-4 bg-white d-flex flex-column" *ngIf="isLogin">

            <div class="row">
              <div class="col-12 py-3"></div>
            </div>
            <div class="row flex-grow-1">
              <div class="col-12 d-flex flex-column justify-content-center">

                <div class="row mb-3">
                  <div class="col-12 text-center">
                    <img src="/assets/images/logo2.png" alt="" class="logo">
                  </div>
                </div>

                <div class="row mb-3">
                  <div class="col-12">
                    <h3 class="text-center">Login</h3>
                  </div>
                </div>

                <div class="row inputs mb-2">
                  <div class="col-12 col-sm-8 offset-sm-2 d-flex justify-content-center">
                    <input fullWidth nbInput placeholder="Nutzername..." class="username" [(ngModel)]="loginName"
                           (keyup)="onKeypress($event)">
                  </div>
                </div>
                <div class="row">
                  <div class="col-12 col-sm-8 offset-sm-2 d-flex justify-content-center">
                    <input fullWidth nbInput placeholder="Passwort..." type="password" [(ngModel)]="password"
                           (keyup)="onKeypress($event)">
                  </div>
                </div>

                <div class="row btnRow mb-2">
                  <div class="col-12 col-sm-8 offset-sm-2 d-flex justify-content-center">
                    <button fullWidth nbButton status="primary" (click)="login()">Anmelden</button>
                  </div>
                </div>

                <!--<div class="row">
                  <div class="col-12 col-sm-8 offset-sm-2 d-flex justify-content-center">
                    <button fullWidth nbButton status="primary" (click)="resetPassword()">Passwort zur&uuml;cksetzen</button>
                  </div>
                </div>-->

              </div>
            </div>
            <div class="row flex-grow-1">
              <div class="col-12">

              </div>
            </div>
          </div>
          <div class="col-8 col-md-4 bg-white d-flex flex-column" *ngIf="!isLogin">

            <div class="row">
              <div class="col-12 py-3"></div>
            </div>
            <div class="row flex-grow-1">
              <div class="col-12 d-flex flex-column justify-content-center">

                <div class="row mb-3">
                  <div class="col-12 text-center">
                    <img src="/assets/images/logo2.png" alt="" class="logo">
                  </div>
                </div>

                <div class="row mb-3">
                  <div class="col-12">
                    <h3 class="text-center">Passwort zur&uuml;cksetzen</h3>
                  </div>
                </div>

                <div class="row mb-3" *ngIf="!resetSuccess">
                  <div class="col-12">
                    <p class="text-center">Geben Sie die E-Mail Adresse Ihres Benutzerkontos an, um das Zur&uuml;cksetzen Ihres Passwortes zu beantragen.</p>
                  </div>
                </div>

                <div class="row mb-3" *ngIf="resetSuccess">
                  <div class="col-12">
                    <p class="text-center">Ihre Anfrage ist bei uns angekommen. In K&uuml;rze werden Sie von uns eine E-Mail mit einem Link zum Zur&uuml;cksetzen des Passwortes erhalten.</p>
                  </div>
                </div>

                <div class="row" *ngIf="!resetSuccess">
                  <div class="col-12 col-sm-8 offset-sm-2 d-flex justify-content-center">
                    <input #emailInput="ngModel" fullWidth nbInput placeholder="Email" type="text" [(ngModel)]="email" [status]="emailInput.dirty && emailValidation() != undefined ? (emailValidation() ? 'success' : 'danger') : 'basic'">
                  </div>
                </div>

                <div class="row btnRow" *ngIf="!resetSuccess">
                  <div class="col-12 col-sm-8 offset-sm-2 d-flex justify-content-center">
                    <button fullWidth nbButton status="primary" (click)="doReset()">Absenden</button>
                  </div>
                </div>

                <div class="row btnRow" *ngIf="resetSuccess">
                  <div class="col-12 col-sm-8 offset-sm-2 d-flex justify-content-center">
                    <button fullWidth nbButton status="primary" (click)="backToLogin()">Zum Login</button>
                  </div>
                </div>

              </div>
            </div>
            <div class="row flex-grow-1">
              <div class="col-12">

              </div>
            </div>
          </div>

        </div>
      </div>

    </div>
  </nb-layout-column>
</nb-layout>
