import {Injectable} from '@angular/core';
import {BasicService} from '../../../utils/basic.service';
import {DataService} from '../../util/data.service';
import {HttpClient} from '@angular/common/http';
import * as ROUTES from '../../../utils/ROUTES';
import {GenericResponse} from '../../../models/util/GenericResponse';
import {Inquiry} from '../../../models/megalog/Inquiry';
import {InternalReferenceResponse} from '../../../models/megalog/InternalReferenceResponse';
import {Invoice} from '../../../models/megalog/Invoice';

@Injectable({
  providedIn: 'root',
})
export class CustomerBasedInvoiceService extends BasicService<Invoice> {


  constructor(
    private dataService: DataService,
    private http: HttpClient
  ) {
    super(dataService, http, `${ROUTES.CUSTOMER_BASED_ROUTE}/invoice`);
  }

  public async getAllCustomerInvoices(): Promise<Invoice[]> {
    let response: Invoice[] = undefined;
    await this.http.get<GenericResponse<Invoice[]>>(`${ROUTES.CUSTOMER_BASED_ROUTE}/invoice`, this.dataService.getHttpOptions()).toPromise()
      .then((res: GenericResponse<Invoice[]>) => {
        if (res != undefined && res.status != undefined && res.status == 200 && res.body != undefined) {
          response = res.body;
        }
      });
    return response;
  }

  public async downloadInvoiceAsPDF(invoiceId: string): Promise<any> {
    let response: any = undefined;
    await this.http.post<any>(`${ROUTES.CUSTOMER_BASED_ROUTE}/invoice/toPDF/${invoiceId}/`, {}, this.dataService.getHttpOptionsBlob()).toPromise()
      .then((res: any) => {
        if (res != undefined && res.status != undefined && res.status == 200 && res.body != undefined) {
          response = res.body;
        }
      });
    return response;
  }

}
