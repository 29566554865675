import {Component, Input, OnInit} from '@angular/core';
import {NbDialogRef} from '@nebular/theme';

@Component({
  selector: 'ngx-confirm',
  templateUrl: './confirm.component.html',
  styleUrls: ['./confirm.component.scss'],
})
export class ConfirmComponent implements OnInit {

  @Input() public headline = 'Eintrag löschen?';
  @Input() public headlineDeactivate = 'Eintrag deaktivieren?';
  @Input() public body = 'Möchten Sie diesen Eintrag löschen?';
  @Input() public bodyDeactivate = 'Möchten Sie diesen Eintrag deaktivieren?';
  @Input() deactivate: boolean = false;
  @Input() public htmlBody: boolean = false;

  constructor(
    protected dialogRef: NbDialogRef<any>
  ) {
  }

  ngOnInit(): void {
    let tmp;
  }

  public confirm(): void {
    this.dialogRef.close({delete: true});
  }

  public dismiss(): void {
    this.dialogRef.close();
  }

}
