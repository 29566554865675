import {Component, Input, OnInit, ViewEncapsulation} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import * as ICONS from '../../../@core/utils/ICONS';
import {NbDialogRef} from '@nebular/theme';
import {UtilsService} from '../../../@core/services/util/utils.service';

@Component({
  selector: 'ngx-pdf-viewer',
  templateUrl: './pdf-viewer.component.html',
  styleUrls: ['./pdf-viewer.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class PdfViewerComponent implements OnInit {

  @Input() public closeOnBackDrop: boolean = true;
  @Input() public pdfSrcRaw;
  @Input() public fileName: string;

  public pdfSrc;
  public zoom: number = 1;
  public icons = ICONS;
  public page: number = 1;
  public maxPages: number = 1;
  public topControlsActive: boolean = true;

  constructor(
    private http: HttpClient,
    private nbDialogRef: NbDialogRef<any>,
    private utils: UtilsService
  ) {
  }

  async ngOnInit(): Promise<void> {
    setTimeout(() => {
      this.topControlsActive = false;
    }, 3000);
    if (typeof this.pdfSrcRaw == 'string') {
      await this.http.get(this.pdfSrcRaw, {responseType: 'blob'}).toPromise()
        .catch()
        .then(async (res: Blob) => {
          this.pdfSrcRaw = res;
          this.pdfSrc = URL.createObjectURL(this.pdfSrcRaw);
        });
    }
    this.pdfSrc = URL.createObjectURL(this.pdfSrcRaw);
  }

  public zoomUp(): void {
    this.zoom += .1;
  }

  public zoomDown(): void {
    if (this.zoom > .1) {
      this.zoom -= .1;
    }
  }

  public backDropClick(event: any): void {
    if (this.closeOnBackDrop && event.target.className.includes('pdfViewer')) {
      this.close();
    } else if (this.pdfSrc == undefined) {
      this.close();
    }
  }

  public close(): void {
    this.nbDialogRef.close();
  }

  public download(): void {
    this.utils.downLoadFile(this.pdfSrcRaw, 'test.pdf');
  }

  public initDone(event: any): void {
    if (event != undefined && event.source != undefined) {
      if (event.source.pdfDocument != undefined && event.source.pdfDocument._pdfInfo != undefined) {
        if (event.source.pdfDocument._pdfInfo.numPages != undefined) {
          this.maxPages = +event.source.pdfDocument._pdfInfo.numPages;
        }
      }
    }
  }

}
