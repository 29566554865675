import { ExtraOptions, RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import {
  NbAuthComponent,
  NbLoginComponent,
  NbLogoutComponent,
  NbRegisterComponent,
  NbRequestPasswordComponent,
  NbResetPasswordComponent,
} from '@nebular/auth';
import {LoginComponent} from './pages/base/login/login.component';
import {AuthGuard} from './@core/services/guards/authGuard.service';
import {InviteComponent} from './pages/base/invite/invite.component';
import {PasswordResetComponent} from './pages/base/password-reset/password-reset.component';

export const routes: Routes = [
  {
    path: 'login',
    component: LoginComponent,
  },  {
    path: 'invite/:token',
    component: InviteComponent,
  },
  {
    path: 'password-reset/:passwordResetToken',
    component: PasswordResetComponent,
  },
  {
    path: '',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/pages.module')
      .then(m => m.PagesModule),
  },
  { path: '**', redirectTo: '' },
];

const config: ExtraOptions = {
  useHash: false,
};

@NgModule({
  imports: [RouterModule.forRoot(routes, config)],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
