import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {Observable, of} from 'rxjs';
import * as ICONS from '../../../@core/utils/ICONS';
import {UtilsService} from '../../../@core/services/util/utils.service';
import {ConfirmComponent} from '../confirm/confirm.component';
import {NbDialogService} from '@nebular/theme';
import {HttpClient} from '@angular/common/http';
import {GenericResponse} from '../../../@core/models/util/GenericResponse';
import {environment} from "../../../../environments/environment";
import {DataService} from '../../../@core/services/util/data.service';
import { JobMeta } from '../../../@core/models/jobs/JobMeta';


export class EntityEditConfig {
  public title: string = ''; // omitted if empty
  public rows?: EntityEditConfigRow[];
  public tabs?: EntityEditConfigTab[] = [];
}

export class EntityEditConfigTab {
  public title: string = '';
  public rows: EntityEditConfigRow[];
}

export class EntityEditConfigRow {
  public cols: EntityEditConfigCol[];
}

export class EntityEditConfigCol {
  public width?: string | number; // representing bootstrap col-lg width; accepted numbers 1-12; default: 12
  public overrideMobileWidth?: string | number; // representing bootstrap col width; accepted numbers 1-12; default: 12
  public additionalClasses?: string; // list of space-separated classNames to be added
  public colType: ColTypes; // defines general type of col (e.g. input, plain text, ...)
  public inputConfig?: InputConfig; // configure input if colType == 'input' || 'textarea'
  public plainTextContent?: string;
  public buttonContent?: string;
  public isSubmitButton?: boolean; // if true button will call submit function
  public labelText?: string;
  public valid?: boolean; // only used for validation. !don't provide this value!
  public isSubItem?: boolean;
  public subItemEndpoint?: string;
  public subConfig?: EntityEditConfig;
  public hideForCustomer: boolean;
}

export class InputConfig {
  public boundPropertyName?: string;
  public inputType: ColInputTypes; // defines type of input if colType == 'input'
  public placeholder?: string; // placeholder used for input
  public disabled?: boolean; // if the input should be disabled / not be editable
  public required?: boolean; // if the input should be required

  // valid for types: text, email, password
  public maxlength?: number | string;
  public minlength?: number | string;
  public pattern?: string;

  // valid for types: date, month, week, time, number
  public max?: number | string;
  public min?: number | string;
  public step?: number | string;

  // valid for textArea
  public rows?: number | string;

  // valid for select
  public selectItems?: string | any[]; // list of Items for selection
  public selectDisplayKey?: string; // PropertyKey of Value which should be displayed in selection
  public selectMappingKey?: string; // PropertyKey which is used to reference selected item on the main entity

  // valid for date
  public handleDateAsTimestamp?: boolean; // if true, the selected date will be converted into a timestamp instead of a date-string
}

export enum ColTypes {
  Input = 'input',
  TextArea = 'textarea',
  Button = 'button',
  Plain = 'plain',
  ProfilePicture = 'profile-picture',
  SimpleList = 'simple-list',
  ObjectList = 'object-list',
  PropertyMap = 'property-map',
}

export enum ColInputTypes {
  Text = 'text',
  Number = 'number',
  Date = 'date',
  Month = 'month',
  Week = 'week',
  Time = 'time',
  Password = 'password',
  Checkbox = 'checkbox',
  Email = 'email',
  Hidden = 'hidden',
  Select = 'select',
}

@Component({
  selector: 'ngx-entity-edit',
  templateUrl: './entity-edit.component.html',
  styleUrls: ['./entity-edit.component.scss'],
})
export class EntityEditComponent implements OnInit, OnChanges {

  @Input() public config: EntityEditConfig;
  @Input() public entity: any;
  @Input() validationCall: EventEmitter<string>;
  @Output() public validateEvent: EventEmitter<{ event: string, value: boolean }> = new EventEmitter<{ event: string, value: boolean }>();
  @Input() public editMode: boolean;
  @Input() public parentEvents: EventEmitter<{ action: string, data?: any }>;

  public displaySelectList: any[] = [];
  public filteredDisplaySelectList$: Observable<any[]>;
  public selectOptionMappingKey: string = '';
  public selectOptionDisplayKey: string = '';
  public showValidation: boolean;
  public editIcon = ICONS.CAMERA;
  public personIcon = ICONS.USER;
  public closeIcon = ICONS.CLOSE;
  public addIcon = ICONS.ADD;
  public deleteIcon = ICONS.DELETE;
  public addParameterIcon = ICONS.PLUS;
  public deleteParameterIcon = ICONS.MINUS;
  public subEntities: {} = {};
  public listInputValues: {} = {};
  public listInputFilters: {} = {};
  public listValues: Map<any, {item: any, hidden: boolean}[]> = new Map<any, {item: any, hidden: boolean}[]>();
  public objectListAutocompleteModels: {} = {};
  public isCustomer: boolean = false;
  public propertyMap: Map<string, {key: any, value: any, error: boolean}[]> = new Map<string, {key: any, value: any, error: boolean}[]>();

  constructor(
    private dialogService: NbDialogService,
    private utils: UtilsService,
    private http: HttpClient,
    private data: DataService
  ) {
  }

  async ngOnInit(): Promise<void> {
    this.isCustomer = this.data.loggedInIsCustomer();
    this.subEntities = {};
    this.listValues = new Map<any, {item: any, hidden: boolean}[]>();
    this.listInputValues = {};
    this.listInputFilters = {};
    this.objectListAutocompleteModels = {};
    if(this.entity == undefined){
      this.entity = {};
    }
    if(this.validationCall != undefined){
      this.validationCall.subscribe(async e => {
        let valid = await this.validate();
        this.validateEvent.next({event: e, value: valid});
      });
    }
    if(this.parentEvents != undefined){
      this.parentEvents.subscribe((res: {action: string, data?: any}) => {
        switch(res.action){
          case 'abort':
            this.configUpdate();
            break;
          default:
            break;
        }
      });
    }
    this.configUpdate();
  }

  async ngOnChanges(changes: SimpleChanges): Promise<void> {
    if (changes.config != undefined) {
      this.configUpdate();
    }
  }

  public async configUpdate(): Promise<void> {
    if (this.config != undefined && this.config.rows != undefined) {
      let rowCount = 0;
      for (let row of this.config.rows) {
        let colCount = 0;
        for (let col of row.cols) {
          if (col != undefined && col.inputConfig != undefined && col.inputConfig.selectItems != undefined) {
            if (typeof col.inputConfig.selectItems == 'string' && col.inputConfig.selectItems.includes('[')) {
              try {
                col.inputConfig.selectItems = JSON.parse(col.inputConfig.selectItems);
              } catch {
                col.inputConfig.selectItems = [];
              }
            } else if (typeof col.inputConfig.selectItems == 'string' && !col.inputConfig.selectItems.includes('[')) {
              col.inputConfig.selectItems = await this.utils.getCollectionFromURL(col.inputConfig.selectItems);
            }
          }
          if (col != undefined && col.colType == ColTypes.SimpleList) {
            this.updateListValues(col);
          }
          if (col != undefined && col.colType == ColTypes.ObjectList) {
            this.updateObjectListValues(col);
          }
          if (col != undefined && col.isSubItem && col.subItemEndpoint != undefined && col.subItemEndpoint.length > 0) {
            this.http.get<GenericResponse<string>>(`${environment.backendURL}${col.subItemEndpoint}/static/definition`, this.data.getHttpOptions()).toPromise()
              .then((res: GenericResponse<string>) => {
                if (res != undefined && res.status != undefined && res.status == 200 && res.body != undefined) {
                  col.subConfig = JSON.parse(res.body);
                  col.subConfig.title = col.labelText;
                  this.subEntities[col.inputConfig.boundPropertyName] = {
                    entity: {},
                    endpoint: col.subItemEndpoint,
                  };
                  if (this.entity[col.inputConfig.boundPropertyName] != undefined && this.entity[col.inputConfig.boundPropertyName].length > 0) {
                    this.http.get<GenericResponse<any>>(`${environment.backendURL}${col.subItemEndpoint}/${this.entity[col.inputConfig.boundPropertyName]}`, this.data.getHttpOptions()).toPromise()
                      .then((res1: GenericResponse<any>) => {
                        if (res1 != undefined && res1.status != undefined && res1.status == 200 && res1.body != undefined) {
                          this.subEntities[col.inputConfig.boundPropertyName].entity = res1.body;
                        }
                      });
                  }
                }
              });
          }
          if (col != undefined && col.colType == ColTypes.PropertyMap && col.inputConfig != undefined) {
            this.propertyMap.set(col.inputConfig.boundPropertyName, []);
            this.handlePropertyMap(col);
          }
          colCount++;
        }
        rowCount++;
      }
    }
  }

  public isArray(obj): boolean{
    return Array.isArray(obj);
  }

  public submit(): void {
    // tslint:disable-next-line:no-console
    console.log('save');
  }

  private filter(value: string): string[] {
    if (value != undefined) {
      let filterValue;
      if (typeof value == 'string') {
        filterValue = value.toLowerCase();
      } else {
        filterValue = value;
      }
      return this.displaySelectList.filter(optionValue => optionValue[this.selectOptionDisplayKey].toLowerCase().includes(filterValue));
    } else {
      return [];
    }
  }

  private filterAndRemoveAlreadyAdded(value: string, c: EntityEditConfigCol): string[] {
    if (value != undefined && this.listValues != undefined && this.listValues[c.inputConfig.boundPropertyName] != undefined) {
      let filterValue;
      if (typeof value == 'string') {
        filterValue = value.toLowerCase();
      } else {
        filterValue = value;
      }
      return this.displaySelectList.filter(optionValue => optionValue[this.selectOptionDisplayKey].toLowerCase().includes(filterValue)).filter(x => this.listValues[c.inputConfig.boundPropertyName].find(z => z.item.id == x.id) == undefined);
    } else {
      return [];
    }
  }

  public filterSelectOptions(value: string, c?: EntityEditConfigCol) {
    if(c != undefined && c.colType == ColTypes.ObjectList){
      this.filteredDisplaySelectList$ = of(this.filterAndRemoveAlreadyAdded(value, c));
    }else{
      this.filteredDisplaySelectList$ = of(this.filter(value));
    }
  }

  public getDisplayForSelectedItem(c: EntityEditConfigCol): string {
    if (c.inputConfig != undefined && c.inputConfig.selectItems != undefined && Array.isArray(c.inputConfig.selectItems)) {
      let found = c.inputConfig.selectItems.find(i => i[c.inputConfig.selectMappingKey] == this.entity[c.inputConfig.boundPropertyName]);
      if (found != undefined) {
        return found[c.inputConfig.selectDisplayKey];
      }
    }
    return '';
  }

  public selectInputFocus(c: EntityEditConfigCol): void {
    if (c.inputConfig != undefined && c.inputConfig.selectItems != undefined && Array.isArray(c.inputConfig.selectItems)) {
      this.displaySelectList = c.inputConfig.selectItems;
      this.selectOptionMappingKey = c.inputConfig.selectMappingKey;
      this.selectOptionDisplayKey = c.inputConfig.selectDisplayKey;
    } else {
      this.displaySelectList = [];
      this.selectOptionMappingKey = '';
      this.selectOptionDisplayKey = '';
    }
    this.filterSelectOptions('', c);
  }

  public selectionChange(event, c: EntityEditConfigCol): void {
    if(c.colType == ColTypes.ObjectList){
      if(this.entity[c.inputConfig.boundPropertyName] == undefined || !Array.isArray(this.entity[c.inputConfig.boundPropertyName])){
        this.entity[c.inputConfig.boundPropertyName] = [];
      }
      this.entity[c.inputConfig.boundPropertyName].push(event);
      this.updateObjectListValues(c);
      setTimeout(() => {
        this.objectListAutocompleteModels[c.inputConfig.boundPropertyName] = '';
      }, 25);
    }else{
      this.entity[c.inputConfig.boundPropertyName] = event;
    }
  }

  private async validate(): Promise<boolean> {
    this.showValidation = false;
    let valid = true;
    for (let r of this.config.rows) {
      for (let c of r.cols) {
        c.valid = true;
        if (c.inputConfig != undefined && !c.isSubItem) {
          let p = this.entity[c.inputConfig.boundPropertyName];
          if (c.inputConfig.required) {
            if (p == undefined || (typeof p == 'string' && p.length <= 0)) {
              if (c.inputConfig.inputType != ColInputTypes.Password || !this.editMode) {
                c.valid = false;
                valid = false;
              }
            } else if(typeof p == 'number' && c.inputConfig.inputType != ColInputTypes.Password) {
              if(c.inputConfig.inputType == ColInputTypes.Date){
                c.valid = p > 0;
              }
            } else {
              if (c.inputConfig.inputType == ColInputTypes.Password) {
                let repeatPassword = this.entity[c.inputConfig.boundPropertyName + '_repeatPassword'];
                if (repeatPassword != p) {
                  c.valid = false;
                  valid = false;
                }
              }
            }
          }
          if (c.inputConfig.inputType == ColInputTypes.Password && p != undefined && p.length > 0) {
            let repeatPassword = this.entity[c.inputConfig.boundPropertyName + '_repeatPassword'];
            if (repeatPassword != p) {
              c.valid = false;
              valid = false;
            }
          }
          if (c.colType == ColTypes.PropertyMap) {
            const isColValid = this.validatePropertieMap(c);
            if (!isColValid) {
              c.valid = false;
              valid = false;
            }
          }
        }
      }
    }
    if (!valid) {
      this.showValidation = true;
    } else {
      for (let property of Object.keys(this.subEntities)) {
        let hasValue = false;
        for(let subProperty of Object.keys(this.subEntities[property].entity)){
          if(subProperty != undefined){
            hasValue = true;
          }
        }
        if (this.subEntities[property].entity.id == undefined || this.subEntities[property].entity.id.length < 1) {
          if(hasValue){
            await this.http.post<GenericResponse<any>>(`${environment.backendURL}${this.subEntities[property].endpoint}`, this.subEntities[property].entity, this.data.getHttpOptions()).toPromise()
              .then((res: GenericResponse<any>) => {
                if (res != undefined && res.status == 200 && res.body != undefined) {
                  this.subEntities[property].entity = res.body;
                  this.entity[property] = res.body.id;
                }
              });
          }
        } else {
          await this.http.put<GenericResponse<any>>(`${environment.backendURL}${this.subEntities[property].endpoint}/${this.subEntities[property].entity.id}`, this.subEntities[property].entity, this.data.getHttpOptions()).toPromise()
            .then((res: GenericResponse<any>) => {
              if (res != undefined && res.status == 200 && res.body != undefined) {
                this.subEntities[property].entity = res.body;
                this.entity[property] = res.body.id;
              }
            });
        }
      }
    }
    return valid;
  }

  public profilePicChange(event, col: EntityEditConfigCol): void{
    if(event == undefined || event.target == undefined || event.target.files == undefined || event.target.files[0] == undefined) return;
    const reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);
    reader.onload = () => {
      this.entity[col.inputConfig.boundPropertyName] = reader.result;
    };
  }

  public async clearAvatar(event, col: EntityEditConfigCol): Promise<void> {
    event.preventDefault();

    const confirm = this.dialogService.open(ConfirmComponent, {
      closeOnBackdropClick: false,
      context: {
        headline: 'Profilbild löschen?',
        body: 'Sind Sie sicher, dass Sie Ihr Profilbild löschen möchten?',
      },
    });
    await confirm.onClose.toPromise()
      .then(res => {
        if (res != undefined && res.delete) {
          this.entity[col.inputConfig.boundPropertyName] = '';
        }
      });
  }

  public updateListValues(c: EntityEditConfigCol): void {
    if(c != undefined && c.inputConfig != undefined && c.inputConfig.boundPropertyName != undefined && Array.isArray(this.entity[c.inputConfig.boundPropertyName])){
      let search = this.listInputFilters[c.inputConfig.boundPropertyName];
      let toReturn: { item: any, hidden: boolean }[] = [];
      for(let item of this.entity[c.inputConfig.boundPropertyName]){
        let toPush: { item: any, hidden: boolean } = {item, hidden: false};
        if(search != undefined && search.length > 0){
          if(!item.includes(search)){
            toPush.hidden = true;
          }
        }
        toReturn.push(toPush);
      }
      this.listValues[c.inputConfig.boundPropertyName] = toReturn;
    }else{
      this.listValues[c.inputConfig.boundPropertyName] = [];
    }
  }

  public updateObjectListValues(c: EntityEditConfigCol): void{
    if(c != undefined && c.inputConfig != undefined && c.inputConfig.boundPropertyName != undefined && Array.isArray(this.entity[c.inputConfig.boundPropertyName])){
      let items = c.inputConfig.selectItems;
      if(items != undefined && Array.isArray(items)){
        let toReturn: { item: any, hidden: boolean }[] = [];
        for(let item of this.entity[c.inputConfig.boundPropertyName]){
          let found = items.find(x => x[c.inputConfig.selectMappingKey] == item);
          if(found != undefined){
            let toPush: { item: any, hidden: boolean } = {item: found, hidden: false};
            toReturn.push(toPush);
          }
        }
        this.listValues[c.inputConfig.boundPropertyName] = toReturn;
      }
    }else{
      this.listValues[c.inputConfig.boundPropertyName] = [];
    }
  }

  public addListValue(c: EntityEditConfigCol): void{
    if((c.inputConfig.disabled != undefined ? c.inputConfig.disabled : false) || !this.editMode) return;
    if(c != undefined && c.inputConfig != undefined && c.inputConfig.boundPropertyName != undefined){
      if(this.entity[c.inputConfig.boundPropertyName] == undefined){
        this.entity[c.inputConfig.boundPropertyName] = [];
      }
      if(Array.isArray(this.entity[c.inputConfig.boundPropertyName])){
        let value = this.listInputValues[c.inputConfig.boundPropertyName];
        if(value != undefined && value.length > 0){
          this.entity[c.inputConfig.boundPropertyName].push(value);
          this.listInputValues[c.inputConfig.boundPropertyName] = '';
          this.listInputFilters[c.inputConfig.boundPropertyName] = '';
        }
      }
      if(c.colType == ColTypes.ObjectList){
        this.updateObjectListValues(c);
      }else{
        this.updateListValues(c);
      }
    }
  }

  public removeListValue(c: EntityEditConfigCol, index: number): void{
    if((c.inputConfig.disabled != undefined ? c.inputConfig.disabled : false) || !this.editMode) return;
    if(c != undefined && c.inputConfig != undefined && c.inputConfig.boundPropertyName != undefined && Array.isArray(this.entity[c.inputConfig.boundPropertyName])){
      this.entity[c.inputConfig.boundPropertyName].splice(index, 1);
      if(c.colType == ColTypes.ObjectList){
        this.updateObjectListValues(c);
      }else{
        this.updateListValues(c);
      }
    }
  }

  public listFilterChange(c: EntityEditConfigCol): void{
    if(c != undefined && c.inputConfig != undefined && c.inputConfig.boundPropertyName != undefined) {
      let value = this.listInputValues[c.inputConfig.boundPropertyName];
      if (value != undefined) {
        this.listInputFilters[c.inputConfig.boundPropertyName] = value;
      }else{
        this.listInputFilters[c.inputConfig.boundPropertyName] = '';
      }
      if(c.colType == ColTypes.ObjectList){
        this.updateObjectListValues(c);
      }else{
        this.updateListValues(c);
      }
    }
  }

  public getPropertyMapValue(c: EntityEditConfigCol, key: any): any {
    if (c.inputConfig == undefined) return '';
    for (let e of this.propertyMap.get(c.inputConfig.boundPropertyName)) {
      if (e.key == key) {
        return e.value;
      }
    }
    return '';
  }

  public addPropertyMapPair(c: EntityEditConfigCol): void {
    if (c.inputConfig == undefined) return;
    this.propertyMap.get(c.inputConfig.boundPropertyName).push({key: '', value: '', error: false});
  }

  public getSpecificMap(c: EntityEditConfigCol): {key: any, value: any, error: boolean}[] {
    if (c.inputConfig == undefined) return [];
    return this.propertyMap.get(c.inputConfig.boundPropertyName);
  }

  public removeMapEntry(c: EntityEditConfigCol, key: any): void {
    if (c.inputConfig == undefined) return;
    const temp = [];
    for (let e of this.propertyMap.get(c.inputConfig.boundPropertyName)) {
      if (e.key != key) {
        temp.push(e);
      }
    }
    this.propertyMap.set(c.inputConfig.boundPropertyName, temp);
  }

  private handlePropertyMap(c: EntityEditConfigCol): void {
    if (c.inputConfig == undefined) return;
    if (this.entity != undefined && c.colType == ColTypes.PropertyMap) {
      this.mapPropertyMap(c, this.entity[c.inputConfig.boundPropertyName]);
    }
  }

  private mapPropertyMap(c: EntityEditConfigCol, map: Map<any, any>): void {
    if (map == undefined || c.inputConfig == undefined) return;
    const temp: any[] = [];
    for (let k of map.keys()) {
      temp.push({key: k, value: k, error: false});
    }
    this.propertyMap.set(c.inputConfig.boundPropertyName, temp);
  }

  private validatePropertieMap(c: EntityEditConfigCol): boolean {
    let valid = true;
    if (c.inputConfig == undefined) return false;
    const keys: any[] = [];
    for (let e of this.propertyMap.get(c.inputConfig.boundPropertyName)) {
      if (e.key == undefined || e.key == '') {
        e.error = true;
        valid = false;
      }else if (keys.find(x => x == e.key) == undefined) {
        keys.push(e.key);
        e.error = false;
      } else {
        e.error = true;
        valid = false;
      }
    }

    if (valid) {
      const map = new Map<any, any>();
      for (let e of this.propertyMap.get(c.inputConfig.boundPropertyName)) {
        map.set(e.key, e.value);
      }
      this.entity.properties = map;
    }

    return valid;
  }

  public dateChange(c: EntityEditConfigCol): void {
    if(this.entity[c.inputConfig.boundPropertyName+'_date-string'] != undefined && this.entity[c.inputConfig.boundPropertyName+'_date-string'].length > 0) {
      this.entity[c.inputConfig.boundPropertyName] = new Date(this.entity[c.inputConfig.boundPropertyName+'_date-string']).getTime();
    }
  }

  public buildDateString(c: EntityEditConfigCol): void {
    let date;
    if(this.entity[c.inputConfig.boundPropertyName] != undefined && Number.isInteger(this.entity[c.inputConfig.boundPropertyName])){
      date = new Date(this.entity[c.inputConfig.boundPropertyName]);
      if(date != undefined){
        this.entity[c.inputConfig.boundPropertyName+'_date-string'] = `${date.getFullYear()}-${(date.getMonth() + 1) > 9 ? date.getMonth() + 1 : '0' + (date.getMonth() + 1)}-${date.getDate() > 9 ? date.getDate() : '0' + date.getDate()}`;
      }
    } else {
      this.entity[c.inputConfig.boundPropertyName+'_date-string'] = '';
    }
  }

}
