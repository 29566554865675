import {Component, Input, OnInit, ViewEncapsulation} from '@angular/core';
import * as ICONS from '../../../@core/utils/ICONS';

@Component({
  selector: 'ngx-icon',
  templateUrl: './icon.component.html',
  styleUrls: ['./icon.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class IconComponent implements OnInit {

  @Input() public icon: string;
  @Input() public color: string;
  public normalColor: string;
  public hoverColor: string;
  @Input() public rotateHover: boolean = false;
  @Input() public zoomHover: boolean = false;
  public icons = ICONS;
  public imageColorFilter: string = '';
  public imageColorFilterHover: string = '';

  ngOnInit(): void {
    switch(this.color){
      case 'white':
        this.hoverColor = '#fff';
        this.normalColor = '#ececec';
        this.imageColorFilter = 'contrast(0) brightness(1.8)';
        this.imageColorFilterHover = 'contrast(0) brightness(2)';
        break;
      case 'black':
        this.hoverColor = '#131313';
        this.normalColor = '#000';
        this.imageColorFilter = 'contrast(0) brightness(0)';
        this.imageColorFilterHover = 'contrast(0) brightness(.2)';
        break;
      default:
        this.hoverColor = '#fff';
        this.normalColor = '#ececec';
        this.imageColorFilter = 'contrast(0) brightness(1.8)';
        this.imageColorFilterHover = 'contrast(0) brightness(2)';
        break;
    }
  }

}
