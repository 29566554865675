<nb-layout>
  <nb-layout-column class="p-0">
    <div class="bg">
      <div class="container">

        <div class="row">
          <div class="col-12">
            <h5>PortToolio - Passwort zur&uuml;cksetzen</h5>
          </div>
        </div>

        <div class="row text" *ngIf="!success">
          <div class="col-12">
            <span>Geben Sie Ihr gew&uuml;nschtes Passwort ein und best&auml;tigen Sie dieses.</span>
          </div>
        </div>

        <div class="row inputs" *ngIf="!success">
          <div class="col-12">
            <input #passwordInput="ngModel" autocomplete="off" nbInput fullWidth type="password" [(ngModel)]="password" placeholder="Passwort" [status]="passwordInput.dirty && validatePassword() != undefined ? (validatePassword() ? 'success' : 'danger') : 'basic'">
          </div>
          <div class="col-12">
            <input #passwordRepeatInput="ngModel" autocomplete="off" nbInput fullWidth type="password" [(ngModel)]="passwordRepeat" placeholder="Passwort bestätigen" [status]="passwordRepeatInput.dirty && validatePassword() != undefined ? (validatePassword() ? 'success' : 'danger') : 'basic'">
          </div>
        </div>

        <div class="row btnRow" *ngIf="!success">
          <div class="col-12">
            <button nbButton (click)="resetPassword()">Passwort Zur&uuml;cksetzen anfragen</button>
          </div>
        </div>

        <div class="row text" *ngIf="success">
          <div class="col-12">
            <span>Ihr Passwort wurde erfolgreich aktualisiert. Sie k&ouml;nnen sich nun einloggen.</span>
          </div>
        </div>

        <div class="row btnRow" *ngIf="success">
          <div class="col-12">
            <button nbButton (click)="toLogin()">Zur&uuml;ck zum Login</button>
          </div>
        </div>

      </div>
    </div>
  </nb-layout-column>
</nb-layout>
