import {HttpClient} from '@angular/common/http';
import {GenericResponse} from '../models/util/GenericResponse';
import {DataService} from '../services/util/data.service';

export class BasicService<T> {

  public routeBase: string = '';

  private data: DataService;
  private h: HttpClient;

  constructor(dataService: DataService, http: HttpClient, route: string) {
    this.data = dataService;
    this.h = http;
    this.routeBase = route;
  }

  public async create(obj: T): Promise<T> {
    let response: T = undefined;
    await this.h.post<GenericResponse<T>>(`${this.routeBase}`, obj, this.data.getHttpOptions()).toPromise()
      .then((res: GenericResponse<T>) => {
        if (res != undefined && res.status != undefined && res.status == 200 && res.body != undefined) {
          response = res.body;
        }
      });
    return response;
  }

  public async update(obj: T): Promise<T> {
    let id = undefined;
    if (obj.hasOwnProperty('id')) {
      // @ts-ignore
      id = obj.id;
    }
    let response: T = undefined;
    await this.h.put<GenericResponse<T>>(`${this.routeBase}/${id}`, Object.assign({},obj), this.data.getHttpOptions()).toPromise()
      .then((res: GenericResponse<T>) => {
        if (res != undefined && res.status != undefined && res.status == 200 && res.body != undefined) {
          response = res.body;
        }
      });
    return response;
  }

  public async copyList(ids: string[]): Promise<T[]> {
    let response: T[] = undefined;
    await this.h.post<GenericResponse<T[]>>(`${this.routeBase}/copy`, ids, this.data.getHttpOptions()).toPromise()
      .then((res: GenericResponse<T[]>) => {
        if (res != undefined && res.status != undefined && res.status == 200 && res.body != undefined) {
          response = res.body;
        }
      });
    return response;
  }

  public async delete(id: string): Promise<boolean> {
    let success = false;
    await this.h.delete<GenericResponse<string>>(`${this.routeBase}/${id}`, this.data.getHttpOptions()).toPromise()
      .then((res: GenericResponse<string>) => {
        if (res != undefined && res.status != undefined && res.status == 200) {
          success = true;
        }
      });
    return success;
  }

  public async deleteList(ids: string[]): Promise<boolean> {
    let success = false;
    await this.h.post<GenericResponse<string>>(`${this.routeBase}/delete`, ids, this.data.getHttpOptions()).toPromise()
      .then((res: GenericResponse<string>) => {
        if (res != undefined && res.status != undefined && res.status == 200) {
          success = true;
        }
      });
    return success;
  }

  public async get(id: string): Promise<T> {
    let response: T = undefined;
    await this.h.get<GenericResponse<T>>(`${this.routeBase}/${id}`, this.data.getHttpOptions()).toPromise()
      .then((res: GenericResponse<T>) => {
        if (res != undefined && res.status != undefined && res.status == 200 && res.body != undefined) {
          response = res.body;
        }
      });
    return response;
  }

  public async getAll(queryParams: string = ''): Promise<T[]> {
    let response: T[] = [];
    await this.h.get<GenericResponse<T[]>>(`${this.routeBase}${queryParams}`, this.data.getHttpOptions()).toPromise()
      .then((res: GenericResponse<T[]>) => {
        if (res != undefined && res.status != undefined && res.status == 200 && res.body != undefined && Array.isArray(res.body)) {
          response = res.body;
        }
      });
    return response;
  }

  public async getContentDefinition(): Promise<string> {
    let response: string;
    await this.h.get<GenericResponse<string>>(`${this.routeBase}/static/definition`, this.data.getHttpOptions()).toPromise()
      .then((res: GenericResponse<string>) => {
        if (res != undefined && res.status != undefined && res.status == 200 && res.body != undefined) {
          response = res.body;
        }
      });
    return response;
  }
}
