import {Component, ElementRef, EventEmitter, Input, OnInit, Renderer2, ViewChild} from '@angular/core';
import * as ICONS from '../../../@core/utils/ICONS';
import {BasicService} from '../../../@core/utils/basic.service';
import {EntityEditConfig} from '../entity-edit/entity-edit.component';
import {NbDialogRef, NbDialogService} from '@nebular/theme';
import {UtilsService} from '../../../@core/services/util/utils.service';
import {ConfirmComponent} from '../confirm/confirm.component';

@Component({
  selector: 'ngx-entity-edit-modal',
  templateUrl: './entity-edit-modal.component.html',
  styleUrls: ['./entity-edit-modal.component.scss'],
})
export class EntityEditModalComponent implements OnInit {
  @ViewChild('componentColParent') public componentColParent: ElementRef;
  @ViewChild('componentCol') public componentCol: ElementRef;

  @Input() public config: EntityEditConfig;
  @Input() public entity: any;
  @Input() public service: BasicService<any>;
  @Input() public isEdit: boolean = true;
  @Input() public events: EventEmitter<{ reload: boolean, customActions: string[] }>;
  @Input() public validation: EventEmitter<string> = new EventEmitter<string>();
  @Input() public useCustomComponent: string;
  @Input() public specificData: any[];
  @Input() public enableCopy: boolean = false;

  public editMode: boolean = false;
  public savedEvent: EventEmitter<any> = new EventEmitter<any>();

  private resizeInterval;
  private originalEntity: any;
  public closeIcon = ICONS.CLOSE;
  public parentEvents: EventEmitter<{ action: string, data?: any }> = new EventEmitter<{ action: string, data?: any }>();

  constructor(
    protected dialogRef: NbDialogRef<any>,
    private utils: UtilsService,
    private renderer: Renderer2,
    private dialogService: NbDialogService
    ) {
  }

  ngOnInit(): void {
    addEventListener('resize', (event) => {
      this.onResize();
    });
    this.resizeInterval = setInterval(() => {
      this.onResize();
    }, 100);
    this.dialogRef.onClose.subscribe(() => {
      clearInterval(this.resizeInterval);
    });
    if(this.entity.id == undefined) {
      this.editMode = true;
    }
    this.originalEntity = this.utils.deepClone([this.entity])[0];
  }

  public abort(): void {
    this.entity = this.utils.deepClone([this.originalEntity])[0];
    setTimeout(() => {
      this.parentEvents.next({action: 'abort'});
    }, 100);
  }

  public onResize(): void{
    if(this.componentCol != undefined && this.componentColParent != undefined){
      let height = this.componentColParent.nativeElement.offsetHeight + 'px';
      this.renderer.setStyle(this.componentCol.nativeElement, 'height', height);
    }
  }

  editModeChange(event: boolean){
    this.editMode = event;
  }

  public didValidate(e: { event: string, value: boolean }): void{
    if(e.event == 'save' && e.value){
      this.save();
    }
  }

  public close(reload: boolean = true): void{
    this.events.emit({reload, customActions: undefined});
    this.utils.closeEditModal();
  }

  public async save(): Promise<void> {
    let entity;
    if (this.isEdit) {
      entity = await this.service.update(this.entity);
    }else {
      entity = await this.service.create(this.entity);
    }
    this.savedEvent.next(entity);
    this.close();
  }

  public validateForSave(): void{
    this.validation.next('save');
  }

  public async delete(): Promise<void> {
    if (this.isEdit) {
      const confirm = this.dialogService.open(ConfirmComponent, {
        closeOnBackdropClick: false,
        context: {
          headline: 'Löschen?',
          body: 'Sind Sie sicher, dass Sie diesen Eintrag löschen möchten?',
        },
      });
      await confirm.onClose.toPromise()
        .then(async res => {
          if (res != undefined && res.delete) {
            await this.service.delete(this.entity.id);
            this.close(true);
          }
        });
    }else{
      this.close();
    }
  }

  public getDisplayComponent(component: string): boolean {
    if (component == this.useCustomComponent) return true;
    return false;
  }

  public copy(): void {
    this.dialogService.open(ConfirmComponent, {
      context: {headline: 'Kopieren', body: `Sind Sie sicher, dass Sie kopieren möchten?`},
      autoFocus: false,
      closeOnBackdropClick: false,
    }).onClose.subscribe({
      next: async (value) => {
        if (value != undefined && value.delete == true) {
          await this.service.copyList([this.entity.id]);
          this.close();
        }
      },
    });
  }

}
