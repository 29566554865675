<div class="container-fluid main-container d-flex flex-column">

  <div class="row mb-5">
    <div class="col-12 d-flex justify-content-between">
      <div class="left d-flex">
        <button nbButton status="primary" class="mr-2" *ngIf="!editMode" (click)="editMode = true"><i class="fas fa-pencil-alt mr-2"></i>Bearbeiten</button>
        <button nbButton status="success" class="mr-2" *ngIf="editMode" (click)="validateForSave()">Speichern</button>
        <button nbButton status="primary" class="mr-2" *ngIf="editMode && getDisplayComponent('custom')" (click)="abort()">Verwerfen</button>
        <button nbButton status="primary" class="mr-2" *ngIf="!editMode && enableCopy" (click)="copy()">+ Kopieren</button>
        <button nbButton status="danger" class="mr-2" (click)="delete()"><i class="fas fa-trash-alt mr-2"></i>L&ouml;schen</button>
      </div>
      <!--
      <nb-toggle status="primary" [(checked)]="editMode">Bearbeitungs-Modus</nb-toggle>
      -->
      <img alt="close" class="pointer closeModal" src="/assets/icons/close_thin.svg" (click)="close(false)">
      <!--
      <button nbButton (click)="close(false)">schließen</button>
      -->
    </div>
  </div>

  <div class="row h-100 overflow-hidden" #componentColParent>
    <div class="col-12 componentCol" #componentCol>
      <ngx-entity-edit *ngIf="getDisplayComponent('custom')" [config]="config" [entity]="entity" [editMode]="editMode" [validationCall]="validation" (validateEvent)="didValidate($event)" [parentEvents]="parentEvents"></ngx-entity-edit>
      <ngx-role-edit *ngIf="getDisplayComponent('role')" [entity]="entity" [editMode]="editMode" [validationCall]="validation" (validateEvent)="didValidate($event)" [specificData]="specificData"></ngx-role-edit>
      <ngx-menu-edit *ngIf="getDisplayComponent('menu')" [entity]="entity" [editMode]="editMode" [validationCall]="validation" (validateEvent)="didValidate($event)" [specificData]="specificData" [savedEvent]="savedEvent" [modalEvents]="events"></ngx-menu-edit>
    </div>
  </div>

<!--
  <div class="row">
    <div class="col-12">
      <div class="container-fluid">
        <div class="row">
          <div class="col-12 d-flex justify-content-between">
            <button nbButton status="primary" (click)="validateForSave()" [disabled]="!editMode">Speichern</button>
            <button nbButton status="danger" (click)="delete()" *ngIf="isEdit" [disabled]="!editMode">Löschen</button>
          </div>
        </div>
      </div>
    </div>
  </div>
  -->
</div>
