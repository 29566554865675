import {HttpClient} from '@angular/common/http';
import {EventEmitter, Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {Observable} from 'rxjs';
import {DataService} from '../util/data.service';
import {GenericResponse} from '../../models/util/GenericResponse';
import * as ROUTES from '../../utils/ROUTES';
import {MenuService} from './menu.service';
import {UtilsService} from '../util/utils.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {

  public loggedInEvent: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    private router: Router,
    private http: HttpClient,
    private dataService: DataService,
    private menuService: MenuService
  ) {
  }

  public async login(email: string, password: string): Promise<boolean> {
    let success = false;
    await this.http.post<GenericResponse<any>>(`${ROUTES.AUTH}/login`, {
      email,
      password,
    }, this.dataService.getHttpOptionsNoToken()).toPromise()
      .then((res: GenericResponse<any>) => {
        if (res != undefined && res.status != undefined && res.status == 200) {
          success = true;
          this.dataService.setToken(res.token);
        }
      });
    return success;
  }

  public async sendPasswordForInvite(registrationToken: string, password: string): Promise<boolean> {
    let success = false;
    await this.http.post<GenericResponse<any>>(`${ROUTES.AUTH}/${registrationToken}/registration`,{password}, this.dataService.getHttpOptionsNoToken()).toPromise()
      .then((res: GenericResponse<any>) => {
        if (res != undefined && res.status != undefined && res.status == 200) {
          success = true;
          this.dataService.setToken(res.token);
        }
      });
    return success;
  }


  public logout(): void {
    this.dataService.clearData();
    this.router.navigate(['/login']);
  }

  public validateToken(): Observable<GenericResponse<string>> {
    return this.http.post<GenericResponse<string>>(`${ROUTES.AUTH}/validate`, {}, this.dataService.getHttpOptions(this.dataService.getToken()));
  }

  public async resetPassword(email: string): Promise<boolean> {
    let success = false;
    await this.http.post<GenericResponse<string>>(`${ROUTES.AUTH}/reset-password/trigger`, {email}, this.dataService.getHttpOptions(this.dataService.getToken())).toPromise()
      .then((res: GenericResponse<string>) => {
        if(res?.status == 200){
          success = true;
        }
      });
    return success;
  }

  public async updatePassword(token: string, password: string): Promise<boolean> {
    let success = false;
    await this.http.post<GenericResponse<string>>(`${ROUTES.AUTH}/reset-password/update`, {token, newPassword: password}, this.dataService.getHttpOptions(this.dataService.getToken())).toPromise()
      .then((res: GenericResponse<string>) => {
        if(res?.status == 200){
          success = true;
        }
      });
    return success;
  }
}
