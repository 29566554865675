import {HttpClient} from '@angular/common/http';
import {EventEmitter, Injectable} from '@angular/core';
import {DataService} from '../util/data.service';
import * as ROUTES from '../../utils/ROUTES';
import {BasicService} from '../../utils/basic.service';
import {Menu} from '../../models/base/Menu';
import {UtilsService} from '../util/utils.service';
import {MenuItemService} from './menu-item.service';
import {NbIconConfig, NbMenuItem} from '@nebular/theme';
import {MenuItem} from '../../models/base/MenuItem';
import {GroupService} from './group.service';
import {UserService} from './user.service';
import {MENU_ITEMS_FALLBACK, MENU_ITEMS} from '../../../pages/pages-menu';

@Injectable({
  providedIn: 'root',
})
export class MenuService extends BasicService<Menu>{

  private loadMenusInterval;
  private menus: Menu[];
  public menuUpdate: EventEmitter<NbMenuItem[]> = new EventEmitter<NbMenuItem[]>();
  public menuUpdateCall: EventEmitter<any> = new EventEmitter<any>();
  private updateMenu: boolean = true;
  private updateMenuInterval;

  constructor(
    private dataService: DataService,
    private http: HttpClient,
    private utils: UtilsService,
    private itemService: MenuItemService,
    private userService: UserService,
    private groupService: GroupService
  ) {
    super(dataService, http, ROUTES.MENU_ROUTE);
    this.loadMenusInterval = setInterval(async () => {
      this.loadMenus();
    }, 30000);
    this.groupService.groupsUpdatedFromUndefined.subscribe(() => {
      this.updateMenu = true;
    });
    this.menuUpdateCall.subscribe(() => {
      this.updateMenu = true;
    });
    this.updateMenuInterval = setInterval(async () => {
      if (this.updateMenu && this.dataService.hasValidToken() && this.userService.getSelf() != undefined) {
        this.updateMenu = false;
        if(!this.dataService.loggedInIsCustomer()) {
          if(this.groupService.getById(this.userService.getSelf().groupId) != undefined){
            await this.buildMenuForGroup(this.groupService.getById(this.userService.getSelf().groupId).menuId);
          }else{
            await this.buildMenuForGroup('', this.userService.getSelf().email == 'info@indevention.de');
          }
        }else{
          let group = await this.groupService.get(this.userService.getSelf().groupId);
          if(group.menuId != undefined){
            await this.buildMenuForGroup(group.menuId);
          }else{
            await this.buildMenuForGroup('', this.userService.getSelf().email == 'info@indevention.de');
          }
        }
      }
    }, 100);
    this.loadMenus();
  }

  public async loadMenus(): Promise<void>{
    if(!this.dataService.loggedInIsCustomer()) {
      if (this.dataService.hasValidToken()) {
        let menus = await this.getAll();
        if (menus != undefined) {
          this.menus = menus;
        }
      }
    } else {
      if (this.dataService.hasValidToken() && this.userService.getSelf() != undefined) {
        let group = await this.groupService.get(this.userService.getSelf().groupId);
        if(group != undefined && group.menuId != undefined){
          let menu = await this.get(group.menuId);
          this.menus = [menu];
        }
      }
    }
  }

  public getMenus(): Menu[]{
    if(this.menus == undefined) return [];
    return this.utils.deepClone(this.menus);
  }

  public getById(id: string): Menu{
    return this.getMenus().find(x => x.id == id);
  }

  public async buildMenuForGroup(menuId: string, isAdmin: boolean = false): Promise<void> {
    if (this.getMenus().length < 1 || !Array.isArray(this.getMenus())) {
      await this.loadMenus();
    }
    if (this.getById(menuId) == undefined) {
      if(isAdmin){
        this.menuUpdate.next(MENU_ITEMS_FALLBACK);
      }else{
        this.menuUpdate.next(MENU_ITEMS);
      }
      return;
      /*
      this.menuUpdate.next([{
        title: 'Home',
        icon: 'home-outline',
        link: '/',
        home: true,
      }]);
       */
    }
    if(this.itemService.getMenuItems().length < 1){
      await this.itemService.loadMenuItems();
    }
    let query = btoa(`menuId=${menuId}`);
    let items: MenuItem[] = await this.itemService.getAll(`?filter=${query}`);
    let baseLevel = items.filter(x => x.parentItemId == undefined || x.parentItemId.length < 1).sort((a, b) => a.levelPosition - b.levelPosition);
    let menu: NbMenuItem[] = [];
    for (let b of baseLevel) {
      menu.push(this.buildItemTree(items, b));
    }
    this.menuUpdate.next(menu);
  }

  private buildItemTree(all: MenuItem[], item: MenuItem): NbMenuItem{
    let childrenItems = all.filter(x => x.parentItemId == item.id);
    childrenItems = childrenItems.sort((a,b) => a.levelPosition - b.levelPosition);
    let children = [];
    for(let c of childrenItems){
      children.push(this.buildItemTree(all, c));
    }
    let toReturn = {
      title: item.name,
      icon: JSON.parse(item.icon) as NbIconConfig,
    };
    if(children.length > 0){
      toReturn['children'] = children;
    }
    if(!item.itemIsFolder){
      toReturn['link'] = '/' + item.link;
    }
    return toReturn;
  }
}
