import {EventEmitter, Injectable} from '@angular/core';
import {DataService} from '../util/data.service';
import {HttpClient} from '@angular/common/http';
import {UtilsService} from '../util/utils.service';
import * as ROUTES from '../../utils/ROUTES';
import {BasicService} from '../../utils/basic.service';
import {Group} from '../../models/base/Group';

@Injectable({
  providedIn: 'root',
})
export class GroupService extends BasicService<Group>{
  private groups: Group[];
  private loadGroupsInterval;
  public groupsUpdatedFromUndefined: EventEmitter<any> = new EventEmitter<any>();
  constructor(
    private dataService: DataService,
    private http: HttpClient,
    private utils: UtilsService
  ) {
    super(dataService, http, ROUTES.GROUP_ROUTE);
    this.loadGroupsInterval = setInterval(async () => {
      if(!this.dataService.loggedInIsCustomer()) {
        this.loadGroups();
      }
    }, 30000);
    if(!this.dataService.loggedInIsCustomer()) {
      this.loadGroups();
    }
  }

  public async loadGroups(): Promise<void>{
    if (this.dataService.hasValidToken()) {
      let groups = await this.getAll();
      if (groups != undefined) {
        let sendEvent = false;
        if(this.groups == undefined){
          sendEvent = true;
        }
        this.groups = groups;
        if(sendEvent){
          this.groupsUpdatedFromUndefined.next();
        }
      }
    }
  }

  public getGroups(): Group[]{
    if(this.groups == undefined) return [];
    return this.utils.deepClone(this.groups);
  }

  public getById(groupId: string): Group{
    if(this.groups == undefined || this.groups.find(x => x.id == groupId) == undefined) return undefined;
    return this.groups.find(x => x.id == groupId);
  }
}
