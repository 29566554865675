import {environment} from "../../../environments/environment";


export const BACKEND_URL = environment.backendURL;
export const USER_ROUTE = `${BACKEND_URL}/v1/user`;
export const PROFILE_ROUTE = `${BACKEND_URL}/v1/profile`;
export const AUTH = `${BACKEND_URL}/auth`;
export const VALIDATE_TOKEN = `${USER_ROUTE}/token/validate/`;
export const GROUP_ROUTE = `${BACKEND_URL}/v1/group`;
export const SETTING_ROUTE = `${BACKEND_URL}/v1/setting`;
export const ROLE_ROUTE = `${BACKEND_URL}/v1/role`;
export const MENU_ROUTE = `${BACKEND_URL}/v1/menu`;
export const MENU_ITEM_ROUTE = `${BACKEND_URL}/v1/menu-item`;
export const DOC_TYPE_ROUTE = `${BACKEND_URL}/v1/doc-type`;
export const DOC_META_ROUTE = `${BACKEND_URL}/v1/doc-meta`;
export const DOC_ACCESS_ROUTE = `${BACKEND_URL}/v1/doc-access`;
export const FILE_ROUTE = `${BACKEND_URL}/v1/file`;
export const CUSTOMER_ROUTE = `${BACKEND_URL}/v1/customer`;
export const CUSTOMER_CONTACTS_ROUTE = `${BACKEND_URL}/v1/customer-contacts`;
export const CUSTOMER_EVENTS_ROUTE = `${BACKEND_URL}/v1/customer-events`;
export const CUSTOMER_STAGE_ROUTE = `${BACKEND_URL}/v1/customer-stage`;
export const ARTICLE_ROUTE = `${BACKEND_URL}/v1/article`;
export const ARTICLE_HISTORY_ROUTE = `${BACKEND_URL}/v1/article-history`;
export const CUSTOMER_BASED_ARTICLE_HISTORY_ROUTE = `${BACKEND_URL}/v1/customer-based/article-history`;
export const INVOICE_ROUTE = `${BACKEND_URL}/v1/invoice`;
export const INVOICE_ITEM_ROUTE = `${BACKEND_URL}/v1/invoice/item`;
export const WAREHOUSE_BOOKING_ROUTE = `${BACKEND_URL}/v1/warehouse-booking`;
export const WAREHOUSE_ROUTE = `${BACKEND_URL}/v1/warehouse`;
export const CUSTOMER_BASED_ROUTE = `${BACKEND_URL}/v1/customer-based`;
export const INQUIRY_ROUTE = `${BACKEND_URL}/v1/inquiry`;
export const INQUIRY_ITEM_ROUTE = `${BACKEND_URL}/v1/inquiry-item`;
export const BILLING_AGREEMENT_ROUTE = `${BACKEND_URL}/v1/agreement/billing`;
export const PAYMENT_AGREEMENT_ROUTE = `${BACKEND_URL}/v1/agreement/payment`;
export const WAREHOUSE_SERVICE_ROUTE = `${BACKEND_URL}/v1/warehouse-service`;
export const WAREHOUSE_SERVICE_ENTRY_ROUTE = `${BACKEND_URL}/v1/warehouse-service-entry`;
export const STOCK_ENTRY_ROUTE = `${BACKEND_URL}/v1/stock-entry`;
export const JOB_META_ROUTE = `${BACKEND_URL}/v1/job-meta`;
export const CUSTOMER_RECEIVER_ROUTE = `${BACKEND_URL}/v1/customer-receiver`;
export const STATISTICS_ROUTE = `${BACKEND_URL}/v1/statistics`;
