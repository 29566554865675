<div class="container-fluid overflow-auto h-100" *ngIf="config != undefined && entity != undefined">
  <div class="row">
    <div class="col-12 row-container">

        <div class="row" *ngIf="config.title != undefined && config.title.length > 0">
          <div class="col-12 text-center config-title">
            {{config.title}}
          </div>
        </div>

      <div class="row justify-content-between">
        <div class="tab" *ngFor="let t of config.tabs">
          {{t.title}}
        </div>
      </div>

        <div class="row" *ngFor="let r of config.rows; let ri = index">

          <div
            *ngFor="let c of r.cols; let ci = index"
            [ngClass]="{'col-12': c.overrideMobileWidth == undefined || c.overrideMobileWidth == '' || c.overrideMobileWidth == 0, 'mt-3': c.isSubItem, 'p-0': c.isSubItem}"
            [class]="'col-lg-' + (c.width != undefined && c.width != '' && c.width != 0 ? c.width : '12') + (c.overrideMobileWidth != undefined && c.overrideMobileWidth != '' && c.overrideMobileWidth != 0 ? ' col-' + c.overrideMobileWidth : '') + (c.additionalClasses != undefined && c.additionalClasses.length > 0 ? ' ' + c.additionalClasses : '')"
          >
            <div class="plainTextContent" *ngIf="c.colType == 'plain' && c.plainTextContent != undefined && c.plainTextContent.length > 0 && !c.isSubItem && (!isCustomer || (isCustomer && !c.hideForCustomer))">
              {{c.plainTextContent}}
            </div>
            <label *ngIf="c.labelText != undefined && (c.inputConfig == undefined || (c.inputConfig != undefined && c.inputConfig.inputType != undefined && c.inputConfig.inputType != 'checkbox')) && !c.isSubItem && (!isCustomer || (isCustomer && !c.hideForCustomer))" [for]="c.inputConfig.boundPropertyName" [ngClass]="{'required': c.inputConfig.required}">{{c.labelText}}</label>
            <input
              nbInput
              fullWidth
              autocomplete="off"
              class="form-control"
              [ngClass]="{'invalid': showValidation && !c.valid}"
              *ngIf="c.colType == 'input' && c.inputConfig.inputType != 'select' && c.inputConfig.inputType != 'checkbox' && (c.inputConfig.inputType != 'date' || (c.inputConfig.inputType == 'date' && !c.inputConfig.handleDateAsTimestamp)) && !c.isSubItem && (!isCustomer || (isCustomer && !c.hideForCustomer))"
              [(ngModel)]="entity[c.inputConfig.boundPropertyName]"
              [type]="c.inputConfig.inputType"
              [id]="c.inputConfig.boundPropertyName"
              [name]="c.inputConfig.boundPropertyName"
              [placeholder]="c.inputConfig.placeholder != undefined ? c.inputConfig.placeholder : ''"
              [disabled]="(c.inputConfig.disabled != undefined ? c.inputConfig.disabled : false) || !editMode"
              [required]="c.inputConfig.required != undefined ? c.inputConfig.required : false"
              [attr.pattern]="c.inputConfig.pattern != undefined ? c.inputConfig.pattern : null"
              [attr.maxLength]="c.inputConfig.maxlength != undefined ? c.inputConfig.maxlength : null"
              [attr.minLength]="c.inputConfig.minlength != undefined ? c.inputConfig.minlength : null"
              [attr.max]="c.inputConfig.max != undefined ? c.inputConfig.max : null"
              [attr.min]="c.inputConfig.min != undefined ? c.inputConfig.min : null"
              [attr.step]="c.inputConfig.step != undefined ? c.inputConfig.step : null"
            >
            <input
            nbInput
            fullWidth
            autocomplete="off"
            class="form-control"
            [ngClass]="{'invalid': showValidation && !c.valid}"
            *ngIf="c.colType == 'input' && c.inputConfig.inputType == 'date' && c.inputConfig.handleDateAsTimestamp && !c.isSubItem && (!isCustomer || (isCustomer && !c.hideForCustomer))"
            [(ngModel)]="entity[c.inputConfig.boundPropertyName+'_date-string'] == undefined ? buildDateString(c) : entity[c.inputConfig.boundPropertyName+'_date-string']"
            (ngModelChange)="dateChange(c)"
            [type]="c.inputConfig.inputType"
            [id]="c.inputConfig.boundPropertyName"
            [name]="c.inputConfig.boundPropertyName"
            [placeholder]="c.inputConfig.placeholder != undefined ? c.inputConfig.placeholder : ''"
            [disabled]="(c.inputConfig.disabled != undefined ? c.inputConfig.disabled : false) || !editMode"
            [required]="c.inputConfig.required != undefined ? c.inputConfig.required : false"
            [attr.pattern]="c.inputConfig.pattern != undefined ? c.inputConfig.pattern : null"
            [attr.maxLength]="c.inputConfig.maxlength != undefined ? c.inputConfig.maxlength : null"
            [attr.minLength]="c.inputConfig.minlength != undefined ? c.inputConfig.minlength : null"
            [attr.max]="c.inputConfig.max != undefined ? c.inputConfig.max : null"
            [attr.min]="c.inputConfig.min != undefined ? c.inputConfig.min : null"
            [attr.step]="c.inputConfig.step != undefined ? c.inputConfig.step : null"
          >
            <label *ngIf="c.labelText != undefined && (c.inputConfig == undefined || (c.inputConfig != undefined && c.inputConfig.inputType != undefined && c.inputConfig.inputType == 'password')) && !c.isSubItem && (!isCustomer || (isCustomer && !c.hideForCustomer))" [for]="c.inputConfig.boundPropertyName+'_repeatPassword'" [ngClass]="{'required': c.inputConfig.required}">{{c.labelText}}</label>
            <input
              nbInput
              fullWidth
              autocomplete="off"
              class="form-control"
              [ngClass]="{'invalid': showValidation && !c.valid}"
              *ngIf="c.colType == 'input' && c.inputConfig.inputType == 'password' && !c.isSubItem && (!isCustomer || (isCustomer && !c.hideForCustomer))"
              [(ngModel)]="entity[c.inputConfig.boundPropertyName+'_repeatPassword']"
              [type]="c.inputConfig.inputType"
              [id]="c.inputConfig.boundPropertyName+'_repeatPassword'"
              [name]="c.inputConfig.boundPropertyName+'_repeatPassword'"
              [placeholder]="c.inputConfig.placeholder != undefined ? c.inputConfig.placeholder : ''"
              [disabled]="(c.inputConfig.disabled != undefined ? c.inputConfig.disabled : false) || !editMode"
              [required]="c.inputConfig.required != undefined ? c.inputConfig.required : false"
              [attr.pattern]="c.inputConfig.pattern != undefined ? c.inputConfig.pattern : null"
              [attr.maxLength]="c.inputConfig.maxlength != undefined ? c.inputConfig.maxlength : null"
              [attr.minLength]="c.inputConfig.minlength != undefined ? c.inputConfig.minlength : null"
              [attr.max]="c.inputConfig.max != undefined ? c.inputConfig.max : null"
              [attr.min]="c.inputConfig.min != undefined ? c.inputConfig.min : null"
              [attr.step]="c.inputConfig.step != undefined ? c.inputConfig.step : null"
            >
            <nb-checkbox
              [status]="showValidation && !c.valid ? 'danger' : 'basic'"
              *ngIf="c.colType == 'input' && c.inputConfig.inputType == 'checkbox' && !c.isSubItem && (!isCustomer || (isCustomer && !c.hideForCustomer))"
              [(ngModel)]="entity[c.inputConfig.boundPropertyName]"
              [disabled]="(c.inputConfig.disabled != undefined ? c.inputConfig.disabled : false) || !editMode"
              [required]="c.inputConfig.required != undefined ? c.inputConfig.required : false"
              [id]="c.inputConfig.boundPropertyName"
            >{{c.labelText != undefined ? c.labelText : ''}}</nb-checkbox>
            <input
              nbInput
              fullWidth
              autocomplete="off"
              class="form-control"
              type="text"
              [ngClass]="{'invalid': showValidation && !c.valid}"
              *ngIf="c.colType == 'input' && c.inputConfig.inputType == 'select' && !c.isSubItem && (!isCustomer || (isCustomer && !c.hideForCustomer))"
              [ngModel]="getDisplayForSelectedItem(c)"
              (ngModelChange)="filterSelectOptions($event)"
              [placeholder]="c.inputConfig.placeholder != undefined ? c.inputConfig.placeholder : ''"
              [disabled]="(c.inputConfig.disabled != undefined ? c.inputConfig.disabled : false) || !editMode"
              [required]="c.inputConfig.required != undefined ? c.inputConfig.required : false"
              [id]="c.inputConfig.boundPropertyName"
              [name]="c.inputConfig.boundPropertyName"
              [nbAutocomplete]="list"
              (focus)="selectInputFocus(c)"
            >
            <nb-autocomplete #list>

              <nb-option *ngFor="let option of filteredDisplaySelectList$ | async" [value]="option[selectOptionDisplayKey]" (click)="selectionChange(option[selectOptionMappingKey], c)">
                {{option[selectOptionDisplayKey]}}
              </nb-option>

            </nb-autocomplete>
            <textarea
              nbInput
              fullWidth
              class="form-control"
              [ngClass]="{'invalid': showValidation && !c.valid}"
              *ngIf="c.colType == 'textarea' && !c.isSubItem && (!isCustomer || (isCustomer && !c.hideForCustomer))"
              [(ngModel)]="entity[c.inputConfig.boundPropertyName]"
              [placeholder]="c.inputConfig.placeholder != undefined ? c.inputConfig.placeholder : ''"
              [name]="c.inputConfig.boundPropertyName"
              [id]="c.inputConfig.boundPropertyName"
              [attr.rows]="c.inputConfig.rows != undefined ? c.inputConfig.rows : null"
              [disabled]="(c.inputConfig.disabled != undefined ? c.inputConfig.disabled : false) || !editMode"
            ></textarea>
            <button
              *ngIf="c.colType == 'button' && !c.isSubItem && (!isCustomer || (isCustomer && !c.hideForCustomer))"
              [disabled]="(c.inputConfig.disabled != undefined ? c.inputConfig.disabled : false) || !editMode"
              (click)="c.isSubmitButton ? submit() : null"
              nbButton
            >{{c.buttonContent}}</button>
            <label *ngIf="c.colType == 'profile-picture' && !c.isSubItem && (!isCustomer || (isCustomer && !c.hideForCustomer))" class="profilePicture" for="profilePicture-{{ri}}-{{ci}}" [style.background-image]="c.colType == 'profile-picture' && entity[c.inputConfig.boundPropertyName] != undefined && entity[c.inputConfig.boundPropertyName].length > 0 ? 'url('+entity[c.inputConfig.boundPropertyName]+')' : undefined">
                <!--
              <img [src]="entity[c.inputConfig.boundPropertyName]" alt="" *ngIf="c.colType == 'profile-picture' && entity[c.inputConfig.boundPropertyName] != undefined && entity[c.inputConfig.boundPropertyName].length > 0"/>
              -->
              <span *ngIf="c.colType == 'profile-picture' && (entity[c.inputConfig.boundPropertyName] == undefined || entity[c.inputConfig.boundPropertyName].length < 1)" class="no-image d-flex justify-content-center align-items-center" [innerHtml]="personIcon"></span>
              <span class="imageOverlay d-flex justify-content-around align-items-center" [ngClass]="{'disabled': (c.inputConfig.disabled != undefined ? c.inputConfig.disabled : false) || !editMode}">
                <span [innerHtml]="editIcon"></span>
                <span [innerHtml]="closeIcon" (click)="clearAvatar($event, c)"></span>
              </span>
            </label>
            <input *ngIf="c.colType == 'profile-picture' && !c.isSubItem && (!isCustomer || (isCustomer && !c.hideForCustomer))" id="profilePicture-{{ri}}-{{ci}}" type="file" accept="image/*" class="hidden" (change)="profilePicChange($event, c)" [disabled]="(c.inputConfig.disabled != undefined ? c.inputConfig.disabled : false) || !editMode">


            <ngx-entity-edit *ngIf="c.isSubItem && c.subConfig != undefined && subEntities[c.inputConfig.boundPropertyName] != undefined && subEntities[c.inputConfig.boundPropertyName].entity != undefined && (!isCustomer || (isCustomer && !c.hideForCustomer))" [config]="c.subConfig" [editMode]="editMode" [entity]="subEntities[c.inputConfig.boundPropertyName].entity"></ngx-entity-edit>

            <div *ngIf="c.colType == 'simple-list' && !c.isSubItem && (!isCustomer || (isCustomer && !c.hideForCustomer))">
              <div class="d-flex justify-content-between align-items-center">
                <input type="text" class="form-control"
                       autocomplete="off"
                       [placeholder]="c.inputConfig.placeholder != undefined ? c.inputConfig.placeholder : ''"
                       [id]="c.inputConfig.boundPropertyName"
                       [(ngModel)]="listInputValues[c.inputConfig.boundPropertyName]"
                       (ngModelChange)="listFilterChange(c)"
                       (keydown.enter)="addListValue(c)"
                       [disabled]="(c.inputConfig.disabled != undefined ? c.inputConfig.disabled : false) || !editMode"
                >
                <span class="ml-3" [innerHtml]="addIcon" (click)="addListValue(c)" [ngClass]="{'pointer': (c.inputConfig.disabled == undefined || !c.inputConfig.disabled) && editMode}"></span>
              </div>
              <nb-list class="mt-2 simple-list custom-scrollbar" *ngIf="listValues[c.inputConfig.boundPropertyName] != undefined">
                <nb-list-item *ngFor="let e of listValues[c.inputConfig.boundPropertyName]; let i = index" class="justify-content-between" [ngClass]="{'hidden': e.hidden}">
                  {{e.item}}<span [innerHtml]="deleteIcon" (click)="removeListValue(c, i)" [ngClass]="{'pointer': (c.inputConfig.disabled == undefined || !c.inputConfig.disabled) && editMode}"></span>
                </nb-list-item>
              </nb-list>
            </div>

            <div *ngIf="c.colType == 'object-list' && !c.isSubItem && (!isCustomer || (isCustomer && !c.hideForCustomer))">
              <div class="d-flex justify-content-between align-items-center">
                <input type="text" class="form-control"
                       autocomplete="off"
                       [value]="objectListAutocompleteModels[c.inputConfig.boundPropertyName]"
                       [(ngModel)]="objectListAutocompleteModels[c.inputConfig.boundPropertyName]"
                       (ngModelChange)="filterSelectOptions($event, c)"
                       [placeholder]="c.inputConfig.placeholder != undefined ? c.inputConfig.placeholder : ''"
                       [disabled]="(c.inputConfig.disabled != undefined ? c.inputConfig.disabled : false) || !editMode"
                       [required]="c.inputConfig.required != undefined ? c.inputConfig.required : false"
                       [id]="c.inputConfig.boundPropertyName"
                       [name]="c.inputConfig.boundPropertyName"
                       [nbAutocomplete]="list"
                       (focus)="selectInputFocus(c)"
                >
              </div>
              <nb-list class="mt-2 simple-list custom-scrollbar" *ngIf="listValues[c.inputConfig.boundPropertyName] != undefined">
                <nb-list-item *ngFor="let e of listValues[c.inputConfig.boundPropertyName]; let i = index" class="justify-content-between" [ngClass]="{'hidden': e.hidden}">
                  {{e.item[c.inputConfig.selectDisplayKey]}}<span [innerHtml]="deleteIcon" (click)="removeListValue(c, i)" [ngClass]="{'pointer': (c.inputConfig.disabled == undefined || !c.inputConfig.disabled) && editMode}"></span>
                </nb-list-item>
              </nb-list>
            </div>

            <div *ngIf="c.colType == 'property-map' && !c.isSubItem && (!isCustomer || (isCustomer && !c.hideForCustomer))">
              <div class="row">
                <div class="col-12">
                  <div class="icon action disabled" [innerHtml]="addParameterIcon" *ngIf="(c.inputConfig.disabled != undefined ? c.inputConfig.disabled : false) || !editMode"></div>
                  <div class="icon action" [innerHtml]="addParameterIcon" (click)="addPropertyMapPair(c)" *ngIf="!((c.inputConfig.disabled != undefined ? c.inputConfig.disabled : false) || !editMode)"></div>
                </div>
              </div>
              <div class="row" *ngFor="let e of getSpecificMap(c)">
                <div class="col-5">
                  <input type="text"
                    autocomplete="off"
                    class="form-control"
                    [(ngModel)]="e.key"
                    [ngClass]="{'invalid': e.error}"
                    [disabled]="(c.inputConfig.disabled != undefined ? c.inputConfig.disabled : false) || !editMode"
                  >
                </div>
                <div class="col-5">
                  <input type="text"
                    autocomplete="off"
                    class="form-control"
                    [(ngModel)]="e.value"
                    [disabled]="(c.inputConfig.disabled != undefined ? c.inputConfig.disabled : false) || !editMode"
                  >
                </div>
                <div class="col-2 iconContainer" *ngIf="!((c.inputConfig.disabled != undefined ? c.inputConfig.disabled : false) || !editMode)">
                  <div class="icon action" [innerHtml]="deleteParameterIcon" (click)="removeMapEntry(c, e.key)"></div>
                </div>
                <div style="flex-grow: 1;"></div>
              </div>
            </div>

          </div>
        </div>

    </div>
  </div>
</div>
