import {Component, EventEmitter, OnDestroy, OnInit} from '@angular/core';
import {NbIconConfig, NbMediaBreakpointsService, NbMenuService, NbSidebarService, NbThemeService} from '@nebular/theme';

import { LayoutService } from '../../../@core/utils';
import {filter, map, takeUntil} from 'rxjs/operators';
import { Subject } from 'rxjs';
import {UserService} from '../../../@core/services/base/user.service';
import {User} from '../../../@core/models/base/User';
import {AuthService} from '../../../@core/services/base/auth.service';
import {EntityEditConfig} from '../../../components/base/entity-edit/entity-edit.component';
import {UtilsService} from '../../../@core/services/util/utils.service';
import { SettingService } from '../../../@core/services/base/setting.service';
import {Setting} from '../../../@core/models/base/Setting';
import {
  CustomerBasedInvoiceService
} from '../../../@core/services/megalog/customer-based/customer-based-invoice.service';
import {Invoice} from '../../../@core/models/megalog/Invoice';

@Component({
  selector: 'ngx-header',
  styleUrls: ['./header.component.scss'],
  templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit, OnDestroy {

  private destroy$: Subject<void> = new Subject<void>();
  userPictureOnly: boolean = false;
  user: User;

  themes = [
    {
      value: 'default',
      name: 'Light',
    },
    {
      value: 'dark',
      name: 'Dark',
    },
    {
      value: 'cosmic',
      name: 'Cosmic',
    },
    {
      value: 'corporate',
      name: 'Corporate',
    },
  ];

  public sidebarCompacted: boolean = false;
  currentTheme = 'default';

  userMenu = [
    {
      title: 'Profil',
      data: 'profile-edit',
      icon: {
        icon: 'user',
        pack: 'font-awesome',
      } as NbIconConfig,
    },
    {
      title: 'Nachtmodus',
      data:'theme-switch',
      icon: {
        icon: 'toggle-off',
        pack: 'font-awesome',
      } as NbIconConfig,
    }, {
      title: 'Log out',
      data: 'logout',
      icon: 'log-out',
    },
  ];
  private conf: EntityEditConfig;
  private modalEvents: EventEmitter<{ reload: boolean, customActions: string[] }> = new EventEmitter<{reload: boolean; customActions: string[]}>();

  public logoBase64String: string;
  public logoSubtitle: string;

  public userIsCustomer: boolean = true;
  public openInvoices: number = 0;

  constructor(private sidebarService: NbSidebarService,
              private menuService: NbMenuService,
              private themeService: NbThemeService,
              private userService: UserService,
              private layoutService: LayoutService,
              private authService: AuthService,
              private breakpointService: NbMediaBreakpointsService,
              private utils: UtilsService,
              private settingService: SettingService,
              private invoiceService: CustomerBasedInvoiceService) {
  }

  async ngOnInit() {
    this.modalEvents.subscribe(res => {
      if(res.reload){
        this.userService.loadSelf();
        this.userService.loadUsers();
      }
      if(res.customActions != undefined){
        for(let action of res.customActions){
          // doAction
        }
      }
    });
    this.currentTheme = this.themeService.currentTheme;
    let menuThemeData = this.userMenu.find(x => x.data == 'theme-switch');
    if (this.currentTheme == 'dark') {
      menuThemeData.icon = {
        icon: 'toggle-on',
        pack: 'font-awesome',
      } as NbIconConfig;
    } else {
      menuThemeData.icon = {
        icon: 'toggle-off',
        pack: 'font-awesome',
      } as NbIconConfig;
    }

    this.user = this.userService.getSelf();
    if(this.user != undefined){
      this.userIsCustomer = this.user.customerId != undefined && this.user.customerId.length > 1;
    }
    this.userService.selfLoadedEvent.subscribe(() => {
      this.user = this.userService.getSelf();
      this.userIsCustomer = this.user.customerId != undefined && this.user.customerId.length > 1;
    });
    this.invoiceService.getAllCustomerInvoices().then((invoices: Invoice[]) => {
      for(let invoice of invoices){
        if(invoice.state != undefined && (invoice.state == 'sent' || invoice.state == 'in_progress')){
          this.openInvoices++;
        }
      }
    });

    this.menuService.onItemClick()
      .pipe(
        filter(({tag}) => tag == 'user-menu')
      )
      .subscribe(data => {
        if (data.item.data == 'logout') {
          this.authService.logout();
        }
        if (data.item.data == 'theme-switch') {
          if ((data.item.icon as NbIconConfig).icon == 'toggle-on') {
            this.userMenu.find(x => x.data == data.item.data).icon = {
              icon: 'toggle-off',
              pack: 'font-awesome',
            } as NbIconConfig;
            this.themeService.changeTheme('default');
            localStorage.setItem('currentTheme', 'default');
          } else {
            this.userMenu.find(x => x.data == data.item.data).icon = {
              icon: 'toggle-on',
              pack: 'font-awesome',
            } as NbIconConfig;
            this.themeService.changeTheme('dark');
            localStorage.setItem('currentTheme', 'dark');
          }
        }
        if(data.item.data == 'profile-edit'){
          if(this.conf != undefined && this.user != undefined){
            this.utils.openEditModal(this.conf, this.user, this.userService, true, this.modalEvents);
          }
        }
      });

    // Responsive & Theme stuff
    const {xl} = this.breakpointService.getBreakpointsMap();
    this.themeService.onMediaQueryChange()
      .pipe(
        map(([, currentBreakpoint]) => currentBreakpoint.width < xl),
        takeUntil(this.destroy$)
      )
      .subscribe((isLessThanXl: boolean) => {
        if (isLessThanXl) {
          if (!this.sidebarCompacted) {
            this.sidebarCompacted = true;
          }
        } else {
          if (this.sidebarCompacted) {
            this.sidebarCompacted = false;
          }
        }
        this.userPictureOnly = isLessThanXl;
      });

    this.themeService.onThemeChange()
      .pipe(
        map(({name}) => name),
        takeUntil(this.destroy$)
      )
      .subscribe(themeName => this.currentTheme = themeName);
    this.sidebarService.getSidebarState('menu-sidebar').subscribe(res => {
      this.sidebarCompacted = res == 'compacted' || res == 'collapsed';
    });


    let conf = await this.userService.getContentDefinition();
    if (conf != undefined && conf.length > 0) {
      this.conf = JSON.parse(conf) as EntityEditConfig;
    }

    await this.loadLogoString();
    this.loadLogoSubtitle();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  changeTheme(themeName: string) {
    this.themeService.changeTheme(themeName);
  }

  toggleSidebar(): boolean {
    this.sidebarService.toggle(true, 'menu-sidebar');
    this.layoutService.changeLayoutSize();
    this.sidebarService.getSidebarState('menu-sidebar').subscribe(res => {
      this.sidebarCompacted = res == 'compacted' || res == 'collapsed';
    });

    return false;
  }

  navigateHome() {
    this.menuService.navigateHome();
    return false;
  }

  private async loadLogoString(): Promise<void> {
    const settings: Setting[] = await this.settingService.getAll();
    settings.forEach(x => {
      if (x.key == 'logo') {
        this.logoBase64String = x.value;
      }
    });
  }

  private async loadLogoSubtitle(): Promise<void> {
    let query = `?filter=${btoa('key=logo.subtitle')}`;
    const settings: Setting[] = await this.settingService.getAll(query);
    if(settings.length > 0 && settings[0] != undefined){
      this.logoSubtitle = settings[0].value;
    }
  }
}
